import { FunctionComponent, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { fetchScheduleLog } from '../../../../../api';
import { useAppDispatch } from '../../../../../shared/hooks/redux-hooks';
import Spinner from '../../../../../shared/ui/Spinner';
import { updatePositionAsync } from '../../../../../store/position/service';
import { Position, ScheduleLog } from '../../../../../types/entities';
import { getDurationString } from '../../../../../utils/datetime-utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

const TimelineDialog: FunctionComponent<Props> = ({ isOpen, onClose, position }: Props) => {
  const [loading, setLoading] = useState(false);
  const [scheduleLog, setScheduleLog] = useState<ScheduleLog[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [useSmallProfit, setUseSmallProfit] = useState(position.useSmallProfit);
  const [smallProfitDelayHours, setSmallProfitDelayHours] = useState(position.smallProfitDelayHours);
  const [smallProfit, setSmallProfit] = useState(position.smallProfit);
  const [useTimeOut, setUseTimeOut] = useState(position.useTimeOut);
  const [timeOutDelayHours, setTimeOutDelayHours] = useState(position.timeOutDelayHours);

  const dispatch = useAppDispatch();

  const handleUpdateSchedule = () => {
    const pos = {
      ...position,
      useSmallProfit,
      smallProfitDelayHours,
      smallProfit,
      useTimeOut,
      timeOutDelayHours
    };
    dispatch(updatePositionAsync(pos));
  };

  const fetchScheduleLogAsync = async () => {
    setLoading(true);
    try {
      const data = (await fetchScheduleLog(position.id)).data;
      setScheduleLog(data);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || error.message;
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchScheduleLogAsync();
    }
  }, [isOpen, position.id]);

  const header = (
    <TableHead>
      <TableRow>
        <TableCell title="Time">Logged</TableCell>
        <TableCell align="right" title="PnL">
          PnL
        </TableCell>
        <TableCell title="Price">Message</TableCell>
      </TableRow>
    </TableHead>
  );

  const rows = scheduleLog.map((x, i) => {
    const loggedDt = new Date(x.logged);
    const formattedLoggedDt = dayjs(loggedDt).format('MM/DD HH:mm A');

    let pnlColor = 'inherit';
    if (x.pnl > 0) {
      pnlColor = '#00a86b';
    } else if (x.pnl < 0) {
      pnlColor = '#e52b50';
    }

    const msgColor = x.isError ? '#e52b50' : 'inherit';
    return (
      <TableRow key={i}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{formattedLoggedDt}</TableCell>
        <TableCell align="right" title="Elapsed" sx={{ color: pnlColor }}>
          ${x.pnl.toFixed(0)}
        </TableCell>
        <TableCell align="left" title="Message" sx={{ color: msgColor, whiteSpace: 'nowrap' }}>
          {x.message}
        </TableCell>
      </TableRow>
    );
  });

  let duration = '';
  if (!!position.entryDate) {
    const entryDt = new Date(position.entryDate);
    duration = getDurationString(entryDt, new Date());
  }

  let formattedExpireDt = '';
  let timeToExpire = '';
  const { expirationDate: expires } = position;
  if (!!expires) {
    const now = new Date();
    const expiresDt = new Date(expires);
    formattedExpireDt = expiresDt.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric' });
    timeToExpire = now > expiresDt ? 'expired' : `in ${getDurationString(now, expiresDt)}`;
  }

  const logTable =
    scheduleLog.length > 0 ? (
      <TableContainer sx={{ my: 2 }}>
        <Table size="small" aria-label="a dense table">
          {header}
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Box sx={{ mb: 1 }}>
        <Typography variant="body2" sx={{ my: 2, ml: 4 }}>
          No log records found..
        </Typography>
        <Divider />
      </Box>
    );

  return (
    <>
      <Spinner loading={loading} />
      <Dialog fullWidth open={isOpen} maxWidth="md" onClose={onClose}>
        <DialogTitle>
          <Box>
            <Typography variant="h5">Position Timeline</Typography>
            {duration && (
              <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                <i>Time Elapsed Since Entry: {duration}</i>
              </Typography>
            )}
            <Typography variant="caption" component="div" sx={{ mt: 1 }}>
              <i>
                Expires: {formattedExpireDt} ({timeToExpire})
              </i>
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          {logTable}
          <Box sx={{ mx: 3, mb: 2 }}>
            <Typography variant="h6">Schedule</Typography>
            <Box sx={{ mt: 3, display: 'flex', gap: 2, justifyContent: 'stretch', alignItems: 'center', flexWrap: 'wrap' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={useSmallProfit}
                    onChange={() => {
                      setUseSmallProfit(!useSmallProfit);
                    }}
                  />
                }
                label="Use Small Profit"
                sx={{ minWidth: '200px' }}
              />
              <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', flexGrow: 1 }}>
                <TextField
                  size="small"
                  label="Hours Delayed"
                  variant="outlined"
                  type="number"
                  value={smallProfitDelayHours}
                  onChange={(e) => setSmallProfitDelayHours(Number(e.target.value))}
                  disabled={!useSmallProfit}
                />
                <TextField
                  size="small"
                  label="Min Profit"
                  variant="outlined"
                  type="number"
                  value={smallProfit}
                  onChange={(e) => setSmallProfit(Number(e.target.value))}
                  disabled={!useSmallProfit}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 3, display: 'flex', gap: 2, justifyContent: 'stretch', alignItems: 'center', flexWrap: 'wrap' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={useTimeOut}
                    onChange={() => {
                      setUseTimeOut(!useTimeOut);
                    }}
                  />
                }
                label="Use Time Out"
                sx={{ minWidth: '200px' }}
              />
              <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', flexGrow: 1 }}>
                <TextField
                  size="small"
                  label="Hours Delayed"
                  variant="outlined"
                  type="number"
                  value={timeOutDelayHours}
                  onChange={(e) => setTimeOutDelayHours(Number(e.target.value))}
                  disabled={!useTimeOut}
                />
              </Box>
            </Box>
          </Box>
          <Divider />
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="primary" onClick={handleUpdateSchedule}>
            Save
          </Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TimelineDialog;
