import { FunctionComponent } from 'react';

import { Card } from '@mui/material';

import { PositionGroup } from '../../../../types/entities';

import PositionGroupBody from './PositionGroupBody';
import PositionGroupHeader from './PositionGroupHeader';

interface Props {
  group: PositionGroup;
}

const PositionGroupPanel: FunctionComponent<Props> = ({ group }: Props) => {
  return (
    <Card sx={{ border: (theme) => `1px solid ${theme.palette.grey[500]}` }}>
      <PositionGroupHeader group={group} />
      <PositionGroupBody group={group} />
    </Card>
  );
};

export default PositionGroupPanel;
