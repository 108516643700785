import { FunctionComponent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Fab, Skeleton, Tooltip, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { KEYCODE_R } from '../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import useKeyDownEvent from '../../../shared/hooks/useKeyDownEvent';
import GutterBox from '../../../shared/ui/GutterBox';
import { loadDashboardAsync } from '../../../store/dashboard/service';

import PositionGroupDialog from './PositionGroupPanel/PositionGroupHeader/PositionGroupDialog';
import { mapSortedGrouplessPositionPanels, mapSortedPositionGroupPanels } from './utils';

export const PositionListPanel: FunctionComponent = () => {
  const { loading, loaded } = useAppSelector((gs) => gs.dashboardState);

  const userSettingsState = useAppSelector((gs) => gs.userSettingsState);
  const { ignoreMissingUnderMarketPrice, useFrozen } = userSettingsState;

  const { loaded: initialLoaded } = useAppSelector((gs) => gs.accountInfoState);

  const { groups } = useAppSelector((gs) => gs.positionGroupState);
  const groupIds = groups.map((x) => x.id);

  const { positions } = useAppSelector((gs) => gs.positionState);
  const activePositions = positions.filter((x) => !x.completed);
  const grouplessPositions = activePositions.filter((x) => !groupIds.includes(x.groupId));

  const [showPositionGroupDialog, setShowPositionGroupDialog] = useState(false);
  const handleAddGroupBtnClick = () => setShowPositionGroupDialog(true);
  const closeShowPositionGroupDialog = () => setShowPositionGroupDialog(false);

  const dispatch = useAppDispatch();
  const reloadDashboard = () => {
    dispatch(loadDashboardAsync(ignoreMissingUnderMarketPrice, useFrozen));
  };

  useKeyDownEvent(KEYCODE_R, () => {
    reloadDashboard();
  });

  useEffect(() => {
    if (!loading && !loaded) {
      reloadDashboard();
    }
  }, [loading, loaded]);

  return (
    <Box>
      <GutterBox sx={{ my: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Positions</Typography>
        <Tooltip title="Add Position Group" placement="left-start">
          <Button size="small" variant="outlined" color="primary" onClick={handleAddGroupBtnClick}>
            <AddIcon />
          </Button>
        </Tooltip>
      </GutterBox>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {mapSortedPositionGroupPanels(groups)}
        {mapSortedGrouplessPositionPanels(grouplessPositions)}
      </Box>
      {!initialLoaded && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Skeleton variant="rounded" animation="wave" height={60} />
          <Skeleton variant="rounded" animation="wave" height={60} />
          <Skeleton variant="rounded" animation="wave" height={60} />
        </Box>
      )}
      <PositionGroupDialog isOpen={showPositionGroupDialog} onClose={closeShowPositionGroupDialog} />
      <Fab
        color="primary"
        aria-label="refresh"
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 16
        }}
        onClick={reloadDashboard}
      >
        {loading ? <CircularProgress color="inherit" size="1.5rem" /> : <RefreshIcon />}
      </Fab>
    </Box>
  );
};
