import { FunctionComponent, useEffect } from 'react';

import { Box, Button, Card } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux-hooks';
import ContractDetailsTable from '../../../../shared/panels/ContractDetailsTable';
import Spinner from '../../../../shared/ui/Spinner';
import { setActiveStep, setContractDetails } from '../../../../store/order-option/reducer';
import { getContractsByUnderlyingSymbolState } from '../../../../store/security-contract/selectors';
import { getSecurityContractsAsync } from '../../../../store/security-contract/service';
import { ContractDetails, Security } from '../../../../types/entities';

const SelectContractStep: FunctionComponent = () => {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const security = orderOptionState.security as Security;
  const { step: activeStep, direction: navDirection } = orderOptionState;
  const underlyingSymbol = security.symbol;
  const contractDetails = orderOptionState.contractDetails;
  const selectedConId = contractDetails?.contract?.conId || 0;

  const conFutState = useAppSelector((gs) => gs.securityContractState);
  const { contracts, loaded, loading } = getContractsByUnderlyingSymbolState(conFutState, underlyingSymbol);

  const dispatch = useAppDispatch();

  const reloadContractMonthsHandler = () => {
    dispatch(getSecurityContractsAsync(security));
  };

  useEffect(() => {
    if (!loaded) {
      reloadContractMonthsHandler();
    }
  }, []);

  // jump-over select contract step if there is only on contract to choose from
  useEffect(() => {
    if (contracts && contracts.length === 1) {
      dispatch(setContractDetails(contracts[0]));
      if (navDirection === 'FORWARD') {
        dispatch(setActiveStep(activeStep + 1));
      } else if (navDirection === 'BACKWARD') {
        dispatch(setActiveStep(activeStep - 1));
      }
    }
  }, [contracts]);

  const selectContractMonthHandler = (details: ContractDetails) => {
    dispatch(setContractDetails(details));
  };

  return !loading && contracts.length !== 1 ? (
    <Box>
      <Card sx={{ my: 2 }}>
        <ContractDetailsTable list={contracts} maxRows={6} selectedConId={selectedConId} onSelect={selectContractMonthHandler} />
      </Card>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button size="small" variant="outlined" onClick={reloadContractMonthsHandler}>
          Reload Contracts
        </Button>
      </Box>
    </Box>
  ) : (
    <Spinner loading={loading} />
  );
};

export default SelectContractStep;
