import { FunctionComponent, useEffect, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import { createTrendLineAsync, removeTrendLineAsync, updateTrendLineAsync } from '../../../store/trend-line/service';
import { ChartTrendLine } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { useAppDispatch } from '../../hooks/redux-hooks';

import { TREND_LINE_COLOR_OPTIONS } from './color-options';

interface Props {
  conId: number;
  isOpen: boolean;
  onClose: () => void;
  tradeLine?: ChartTrendLine;
}

const TrendLineDialog: FunctionComponent<Props> = ({ conId, isOpen, onClose, tradeLine }: Props) => {
  const tradeLineTemplate: ChartTrendLine = {
    id: '',
    conId,
    name: '',
    color: '#ff9800',
    price: 0,
    specificOnly: false,
    specificBarSize: BarSize._1_DAY
  };

  const [tradeLineForm, setTradeLineForm] = useState<ChartTrendLine>(tradeLine || tradeLineTemplate);

  useEffect(() => {
    if (isOpen && tradeLine) {
      setTradeLineForm(tradeLine);
    }
  }, [tradeLine, isOpen]);

  // reset form
  useEffect(() => {
    if (!isOpen) {
      setTradeLineForm(tradeLineTemplate);
    }
  }, [isOpen]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateTradeLineForm = (field: keyof ChartTrendLine, value: any) => {
    const copy = {
      ...tradeLineForm,
      [field]: value
    } as ChartTrendLine;
    setTradeLineForm(copy);
  };

  const dispatch = useAppDispatch();

  const handleSaveLine = () => {
    const isNewLine = !tradeLine;
    if (isNewLine) {
      dispatch(createTrendLineAsync(tradeLineForm));
    } else {
      dispatch(updateTrendLineAsync(tradeLineForm));
    }
    onClose();
  };

  const handleRemoveLine = () => {
    const isNewLink = !tradeLine;
    if (!isNewLink) {
      dispatch(removeTrendLineAsync(tradeLineForm));
    }
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <Typography variant="h6">Trend Line</Typography>
        </Box>

        <Box>
          <Typography variant="caption" component="div" ml={1} mb={1}>
            Color
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
            {TREND_LINE_COLOR_OPTIONS.map((opt) => (
              <IconButton
                key={opt.value}
                onClick={() => updateTradeLineForm('color', opt.value)}
                sx={{
                  backgroundColor: opt.value,
                  height: '32px',
                  width: '32px',
                  outline: opt.value === tradeLineForm.color ? '3px solid white' : 'none'
                }}
                aria-label={opt.colorName}
              />
            ))}
          </Box>
        </Box>

        <FormControl>
          <TextField
            name="price"
            size="small"
            label="Price"
            variant="outlined"
            type="number"
            value={tradeLineForm.price || ''}
            onChange={(e) => updateTradeLineForm('price', parseFloat(e.target.value))}
          />
        </FormControl>

        <FormControl>
          <TextField
            name="name"
            size="small"
            label="Comment"
            variant="outlined"
            type="text"
            value={tradeLineForm.name || ''}
            onChange={(e) => updateTradeLineForm('name', e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={tradeLineForm.specificOnly}
                onChange={() => updateTradeLineForm('specificOnly', !tradeLineForm.specificOnly)}
              />
            }
            label="Specific Chart Type Only"
            sx={{ minWidth: '200px' }}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="security-label" size="small">
            Type
          </InputLabel>
          <Select
            name="specificBarSize"
            labelId="type"
            value={tradeLineForm.specificBarSize}
            label="Bar Size"
            onChange={(e) => updateTradeLineForm('specificBarSize', e.target.value)}
            disabled={!tradeLineForm.specificOnly}
            size="small"
          >
            <MenuItem value={BarSize._1_DAY}>DAY</MenuItem>
            <MenuItem value={BarSize._1_HR}>HOUR</MenuItem>
            <MenuItem value={BarSize._15_MIN}>15MIN</MenuItem>
          </Select>
        </FormControl>
        <Box display="flex" justifyContent="flex-end">
          <FormControl>
            <Tooltip title="Toggle Hide/Show Trend Line">
              <IconButton size="small" onClick={() => updateTradeLineForm('hidden', !tradeLineForm.hidden)}>
                {tradeLineForm.hidden === true ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {tradeLine && (
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1, justifyContent: 'flex-start' }}>
            <Button variant="contained" color="error" onClick={handleRemoveLine}>
              Remove
            </Button>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleSaveLine}>
            Save
          </Button>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TrendLineDialog;
