import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Alert, Box, Button, Chip, Container, Tab, Tabs, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux-hooks';
import GutterBox from '../../../../shared/ui/GutterBox';
import Spinner from '../../../../shared/ui/Spinner';
import { setTradingClass } from '../../../../store/order-option/reducer';
import { getTradingClassByConId } from '../../../../store/trading-class/selectors';
import { getTradingClassesAsync } from '../../../../store/trading-class/service';
import { ContractDetails, TradingClass, TradingClassParams } from '../../../../types/entities';
import { themeColors } from '../../../../utils/color-utils';

const LastTradeDateStep: FunctionComponent = () => {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const contractDetails = orderOptionState.contractDetails as ContractDetails;
  const contract = contractDetails.contract;

  const selectedTradingClass = orderOptionState.tradingClass as TradingClass;

  const tradingClassState = useAppSelector((gs) => gs.tradingClassState);
  const tradingClassStateByConId = getTradingClassByConId(tradingClassState, contract.conId);
  const loading = tradingClassStateByConId.loading;
  const loaded = tradingClassStateByConId.loaded;
  const error = tradingClassStateByConId.error;
  const tradingClasses = tradingClassStateByConId.tradingClasses;

  const dispatch = useAppDispatch();

  const reloadTradingClassesHandler = () => {
    const params: TradingClassParams = {
      conId: contract.conId,
      secType: contract.secType,
      symbol: contract.symbol,
      exchange: contract.exchange
    };
    dispatch(getTradingClassesAsync(params));
  };

  useEffect(() => {
    if (!loaded) {
      reloadTradingClassesHandler();
    }
  }, []);

  const selectTradingClassHandler = (tradingClass: TradingClass) => {
    if (tradingClass.lastTradeDate === selectedTradingClass?.lastTradeDate) {
      dispatch(setTradingClass(undefined));
    } else {
      dispatch(setTradingClass(tradingClass));
    }
  };

  type OptionMonth = { key: string; monthName: string; month: number; year: number };

  const uniqueMonths = useMemo(() => {
    const months: OptionMonth[] = [];
    for (let i = 0; i < tradingClasses.length; i++) {
      const tc = tradingClasses[i];
      const exists = months.filter((x) => x.month === tc.expiresMonth && x.year === tc.expiresYear).length !== 0;
      if (!exists) {
        const dt = new Date(tc.expiresYear, tc.expiresMonth - 1, tc.expiresDay);
        months.push({
          key: `${dt.getFullYear()}-${dt.getMonth()}`,
          monthName: dt.toLocaleDateString('en-US', { month: 'short' }),
          month: tc.expiresMonth,
          year: tc.expiresYear
        });
      }
    }
    return months;
  }, [tradingClasses]);

  const [selectedTab, setSelectedTab] = useState<string | undefined>(uniqueMonths[0]?.key);
  const [selectedMonth, setSelectedMonth] = useState<OptionMonth | undefined>();

  useEffect(() => {
    if (uniqueMonths.length > 0) {
      setSelectedTab(uniqueMonths[0]?.key);
    }
  }, [uniqueMonths]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const selected = uniqueMonths.find((x) => x.key === selectedTab);
    setSelectedMonth(selected);
  }, [selectedTab]);

  const getButtonStyling = (selected: boolean) =>
    selected
      ? {
          color: 'white',
          backgroundColor: themeColors.btnSelected,
          ':hover': {
            bgcolor: '#b33700'
          }
        }
      : {};

  const datesInMonth = tradingClasses
    .filter((x) => {
      return !!selectedMonth && x.expiresYear === selectedMonth.year && x.expiresMonth === selectedMonth.month;
    })
    .map((x) => {
      const nt = new Date(x.expiresYear, x.expiresMonth - 1, x.expiresDay);
      const day = dayjs(nt).format('ddd D.');
      const hoursApart = dayjs(nt).diff(new Date(), 'hour');
      const daysApart = hoursApart / 24;
      const label = `${day} (${Math.ceil(daysApart)}d)`;
      return (
        <Chip
          key={x.lastTradeDate}
          label={label}
          onClick={() => selectTradingClassHandler(x)}
          sx={getButtonStyling(x.expires === selectedTradingClass?.expires)}
        />
      );
    });

  let alertPanel: JSX.Element | undefined;
  if (error) {
    alertPanel = (
      <Alert sx={{ m: 2 }} severity="error">
        {error}
      </Alert>
    );
  }

  const tabs: JSX.Element[] = uniqueMonths.map((optMonth: OptionMonth) => {
    return <Tab key={optMonth.key} label={`${optMonth.monthName} ${optMonth.year - 2000}`} value={optMonth.key}></Tab>;
  });

  return (
    <Box>
      <Spinner loading={loading} />
      {alertPanel}
      {!!selectedTab ? (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              '& .MuiTabs-flexContainer': {
                flexWrap: 'wrap'
              }
            }}
          >
            {tabs}
          </Tabs>
        </Box>
      ) : (
        <Typography sx={{ mt: 3, ml: 2 }} fontSize="smaller">
          No expiredates found..
        </Typography>
      )}
      <Container>
        <Box sx={{ my: 3, display: 'flex', flexWrap: 'wrap', gap: 3 }}>{datesInMonth}</Box>
      </Container>

      <GutterBox sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button size="small" variant="outlined" onClick={reloadTradingClassesHandler}>
          Reload Trading Classes
        </Button>
      </GutterBox>
    </Box>
  );
};

export default LastTradeDateStep;
