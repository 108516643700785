import { FunctionComponent } from 'react';

import { Position } from '../../../../../../types/entities';

import OptionPositionSummary from './OptionPositionSummary';
import UnderlyingPositionSummary from './UnderlyingPositionSummary';

interface Props {
  position: Position;
}

const PositionSummary: FunctionComponent<Props> = ({ position }: Props) => {
  const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';
  return isUnderlying ? <UnderlyingPositionSummary position={position} /> : <OptionPositionSummary position={position} />;
};

export default PositionSummary;
