import React, { FunctionComponent, useEffect } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux-hooks';
import { getMarketDataStateByConId } from '../../../../store/market-data/selectors';
import { setEntryPrice, setMarketPrice, setOrderAction, setOrderType } from '../../../../store/order-underlying/reducer';
import { ContractDetails } from '../../../../types/entities';

const OrderTypePanel: FunctionComponent = () => {
  const orderUnderlyingState = useAppSelector((gs) => gs.orderUnderlyingState);
  const details = orderUnderlyingState.contractDetails as ContractDetails;
  const contract = details.contract;
  const { orderAction, orderType, entryPrice: limitPrice } = orderUnderlyingState;

  const marketDataState = useAppSelector((gs) => gs.marketDataState);
  const marketDataByConId = getMarketDataStateByConId(marketDataState, contract.conId);
  const { marketData } = marketDataByConId;
  let marketPrice: number | undefined;
  if (!!marketData) {
    const { lastPrice, askPrice, bidPrice } = marketData;
    marketPrice = lastPrice > 0 ? lastPrice : (askPrice + bidPrice) / 2;
  }

  const dispatch = useAppDispatch();
  // reset limit
  useEffect(() => {
    if (marketPrice) {
      dispatch(setMarketPrice(marketPrice));
      dispatch(setEntryPrice(marketPrice));
    }
  }, [orderAction, orderType, marketPrice]);

  const setLimitPriceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const limitPrice = Number(e.target.value);
    dispatch(setEntryPrice(limitPrice));
  };

  const priceLabel = orderType === 'LMT' ? 'Limit Price' : orderType === 'STP' ? 'Stop Price' : 'Market Price';

  let customPriceErrorMessage: string | undefined;
  if (marketPrice) {
    if (orderType === 'LMT') {
      if (orderAction === 'BUY' && limitPrice > marketPrice) {
        customPriceErrorMessage = 'BUY-LMT (implies buy-in low) above market price will trigger instantly.';
      } else if (orderAction === 'SELL' && limitPrice < marketPrice) {
        customPriceErrorMessage = 'SELL-LMT (implies sell-in high) below market price will trigger instantly.';
      }
    } else if (orderType === 'STP') {
      if (orderAction === 'BUY' && limitPrice < marketPrice) {
        customPriceErrorMessage = 'BUY-STP (implies a short position) below market price will trigger instantly.';
      } else if (orderAction === 'SELL' && limitPrice > marketPrice) {
        customPriceErrorMessage = 'SELL-STP (implies a long position) above market price will trigger instantly.';
      }
    }
  }

  return (
    <Card sx={{ my: 2, p: 1 }}>
      <Box>
        <Typography variant="h6" sx={{ ml: 1, mb: 2 }} component="div">
          Order Type
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button onClick={() => dispatch(setOrderAction('BUY'))} variant={orderAction === 'BUY' ? 'contained' : 'outlined'}>
                Buy
              </Button>
              <Button onClick={() => dispatch(setOrderAction('SELL'))} variant={orderAction === 'SELL' ? 'contained' : 'outlined'}>
                Sell
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ButtonGroup variant="outlined" fullWidth>
              <Button onClick={() => dispatch(setOrderType('MKT'))} variant={orderType === 'MKT' ? 'contained' : 'outlined'}>
                Market
              </Button>
              <Button onClick={() => dispatch(setOrderType('LMT'))} variant={orderType === 'LMT' ? 'contained' : 'outlined'}>
                Limit
              </Button>
              <Button onClick={() => dispatch(setOrderType('STP'))} variant={orderType === 'STP' ? 'contained' : 'outlined'}>
                Stop
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl sx={{ width: '100%' }} error={!!customPriceErrorMessage}>
              <InputLabel htmlFor="outlined-adornment-price">{priceLabel}</InputLabel>
              <OutlinedInput
                value={limitPrice}
                onChange={setLimitPriceHandler}
                size="small"
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label={priceLabel}
                disabled={orderType === 'MKT'}
              />
              {customPriceErrorMessage && <FormHelperText>{customPriceErrorMessage}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default OrderTypePanel;
