import { FunctionComponent } from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, IconButton, Paper, Skeleton, Tooltip, Typography } from '@mui/material';

import { addFavoriteAsync, removeFavoriteAsync } from '../../../store/favorite-contract/service';
import { Contract, FavoriteContract, Security } from '../../../types/entities';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import GutterBox from '../../ui/GutterBox';

interface Props {
  security: Security;
  contract: Contract | FavoriteContract;
}

const FavoriteHeader: FunctionComponent<Props> = ({ security, contract }) => {
  const { favorites, loading } = useAppSelector((gs) => gs.favoriteContractState);
  const favorite = favorites.find((x) => x.conId === contract.conId);

  const dispatch = useAppDispatch();

  const handleToggleFavoriteButton = () => {
    if (!!favorite) {
      dispatch(removeFavoriteAsync(favorite));
    } else {
      const fav: FavoriteContract = {
        conId: contract.conId,
        symbol: contract.symbol,
        localSymbol: contract.localSymbol,
        exchange: contract.exchange,
        currency: contract.currency,
        secType: contract.secType,
        securityName: security.name
      };
      dispatch(addFavoriteAsync(fav));
    }
  };

  const title = `${security.name} - ${contract.localSymbol}`;

  if (loading) {
    return (
      <GutterBox>
        <Skeleton variant="rounded" animation="wave" height={50} />
      </GutterBox>
    );
  }

  return (
    <Box>
      <Paper sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">Research Board</Typography>
          <Typography variant="caption">{title}</Typography>
        </Box>
        <Tooltip title="Add to favorites" placement="left-start">
          <IconButton size="small" color="primary" onClick={handleToggleFavoriteButton}>
            {!!favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </Tooltip>
      </Paper>
    </Box>
  );
};

export default FavoriteHeader;
