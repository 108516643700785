import { FunctionComponent } from 'react';

import { Box, Button, Card, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { ORDER_PAGE_DAILY_CHART_ID } from '../../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux-hooks';
import AccordionWrapper from '../../../../shared/panels/AccordionWrapper';
import BarChartPanel from '../../../../shared/panels/BarChartPanel';
import TrendLinePanel from '../../../../shared/panels/TrendLinePanel';
import Spinner from '../../../../shared/ui/Spinner';
import { getMarketDataStateByConId } from '../../../../store/market-data/selectors';
import { getOptionChainStateByComposite } from '../../../../store/option-chain/selectors';
import { fetchOptionChainAsync } from '../../../../store/option-chain/service';
import { setOptionContract } from '../../../../store/order-option/reducer';
import { setHourGraphCollapsed } from '../../../../store/user-settings/reducer';
import { ContractDetails, ContractMarketData, OptionChainParams, OptionContract, TradingClass } from '../../../../types/entities';

import OptionChainTable from './OptionChainTable';
import OptionDetailsPanel from './OptionDetailsPanel';
import OrderTypePanel from './OrderTypePanel';

const OptionChainStep: FunctionComponent = () => {
  const { hourGraphCollapsed, useFrozen } = useAppSelector((gs) => gs.userSettingsState);

  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const contractDetails = orderOptionState.contractDetails as ContractDetails;
  const contract = contractDetails.contract;
  const selectedOption = orderOptionState.option;
  const selectedOptionPartialName = selectedOption ? ` - ${selectedOption?.localSymbol}` : '';
  const optionConId = selectedOption?.conId || 0;
  const { entryPrice: limitPrice, orderAction } = orderOptionState;

  const marketDataState = useAppSelector((gs) => gs.marketDataState);
  const marketDataByConId = getMarketDataStateByConId(marketDataState, contract.conId);
  const marketData = marketDataByConId.marketData || ({} as ContractMarketData);
  const { lastPrice, askPrice, bidPrice } = marketData;
  const marketPrice = lastPrice > 0 ? lastPrice : (askPrice + bidPrice) / 2;

  const tradingClass = orderOptionState.tradingClass as TradingClass;
  const tradingClassLastTradeDate = tradingClass?.lastTradeDate || '';

  const optionChainState = useAppSelector((gs) => gs.optionChainState);
  const optionChainStateByConIdAndRight = getOptionChainStateByComposite(
    optionChainState,
    contract.conId,
    tradingClassLastTradeDate,
    orderOptionState.optionRight
  );
  const options = optionChainStateByConIdAndRight.options;
  const optionChainLoading = optionChainStateByConIdAndRight.loading;
  const optionChainError = optionChainStateByConIdAndRight.error;

  const dispatch = useAppDispatch();

  const toggleHourGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setHourGraphCollapsed(!expanded));

  const handleSelectOption = (opt: OptionContract) => {
    if (opt.conId === optionConId) {
      dispatch(setOptionContract(undefined));
    } else {
      dispatch(setOptionContract(opt));
    }
  };

  const reloadOptionChainHandler = () => {
    if (orderOptionState.optionRight === '') {
      return;
    }
    const params: OptionChainParams = {
      conId: contract.conId,
      symbol: contract.symbol,
      localSymbol: contract.localSymbol,
      secType: contract.secType,
      exchange: contract.exchange,
      currency: contract.currency,
      multiplier: contract.secType === 'FUT' ? contract.multiplier : '100', // 100 is default for stock multiplier
      right: orderOptionState.optionRight,
      tradingClass: tradingClass?.name || '',
      tradingClassLastTradeDate: tradingClass?.lastTradeDate || '',
      impliedVolatility: marketData.impliedVolatility,
      useSnapshot: false,
      useFrozen,
      showInTheMoney: true
    };
    dispatch(fetchOptionChainAsync(params));
  };

  const day = dayjs(tradingClass.expires).format('ddd D.');
  const daysApart = dayjs(tradingClass.expires).diff(dayjs(), 'day');
  const expiresLabel = `${day} (${daysApart}d)`;
  const title = `${orderOptionState.optionRight} Option Chain`;
  const subTitle = `${orderOptionState.orderAction} ${orderOptionState.optionRight} | ${expiresLabel}`;

  return (
    <Box>
      <Spinner loading={optionChainLoading} />

      <OrderTypePanel reloadOptionChain={reloadOptionChainHandler} />

      <Card sx={{ p: 2, mb: 2 }}>
        <Box sx={{ ml: 1, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="caption">{subTitle}</Typography>
        </Box>

        {!optionChainError && (
          <OptionChainTable marketPrice={marketPrice} options={options} optionSelected={optionConId} onOptionClick={handleSelectOption} />
        )}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button size="small" variant="outlined" onClick={reloadOptionChainHandler}>
            Reload Option Chain
          </Button>
        </Box>
      </Card>

      <Card>
        {selectedOption && <OptionDetailsPanel option={selectedOption} orderAction={orderAction} />}
        <Box sx={{ p: 1 }}>
          <BarChartPanel
            barChartId={ORDER_PAGE_DAILY_CHART_ID}
            conId={contract.conId}
            title={`${contract.localSymbol}${selectedOptionPartialName}`}
            exchange={contract.exchange}
            entryPrice={limitPrice}
            strikePrice={selectedOption?.strikePrice}
            action={orderAction}
          />
        </Box>
        <AccordionWrapper title="Trend Lines" collapsed={hourGraphCollapsed} onChange={toggleHourGraphCollapsed}>
          <TrendLinePanel conId={contract.conId} />
        </AccordionWrapper>
      </Card>
    </Box>
  );
};

export default OptionChainStep;
