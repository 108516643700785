import { FunctionComponent } from 'react';

import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import WizardNav from '../../../shared/wizard/WizardNav';
import WizardStep from '../../../shared/wizard/WizardStep';
import { reset, setActiveStep, setNavDirection } from '../../../store/order-option/reducer';

import ContractResearchStep from './ContractResearchStep';
import LastTradeDateStep from './LastTradeDateStep';
import MarginOverviewStep from './MarginOverviewStep';
import OptionChainStep from './OptionChainStep';
import PlaceOrderStep from './PlaceOrderStep';
import SelectContractStep from './SelectContractStep';
import SelectSecurityStep from './SelectSecurityStep';

const OptionOrder: FunctionComponent = () => {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const activeStep = orderOptionState.step;
  const dispatch = useAppDispatch();

  const validateNext = (step: number) => {
    switch (step) {
      case 0:
        return !!orderOptionState.security;
      case 1:
        return !!orderOptionState.contractDetails;
      // case 2:
      //   return orderOptionState.orderAction !== '' && orderOptionState.optionRight !== '';
      case 3:
        return !!orderOptionState.tradingClass;
      case 4:
        return !!orderOptionState.option;
      default:
        return true;
    }
  };

  const handleNext = () => {
    dispatch(setActiveStep(activeStep + 1));
    dispatch(setNavDirection('FORWARD'));
  };

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
    dispatch(setNavDirection('BACKWARD'));
  };

  const handleReset = () => {
    dispatch(reset());
  };

  return (
    <>
      <WizardStep step={0} activeStep={activeStep} label="Select Underlying">
        <SelectSecurityStep />
      </WizardStep>

      <WizardStep step={1} activeStep={activeStep} label="Select Contract Month">
        <SelectContractStep />
      </WizardStep>

      <WizardStep step={2} activeStep={activeStep}>
        <ContractResearchStep />
      </WizardStep>

      <WizardStep step={3} activeStep={activeStep} label="Select Last Trade Date">
        <LastTradeDateStep />
      </WizardStep>

      <WizardStep step={4} activeStep={activeStep} label="Select Option">
        <OptionChainStep />
      </WizardStep>

      <WizardStep step={5} activeStep={activeStep} label="Check Margins">
        <MarginOverviewStep />
      </WizardStep>

      <WizardStep step={6} activeStep={activeStep} label="Place Option Order">
        <PlaceOrderStep />
      </WizardStep>

      <WizardNav
        steps={7}
        activeStep={activeStep}
        validateNext={validateNext}
        next={handleNext}
        previous={handleBack}
        reset={handleReset}
      />
    </>
  );
};

export default OptionOrder;
