import { Security } from '../../types/entities';

import { securities } from './securities';

export interface SecurityState {
  securities: Security[];
}

export const initialState: SecurityState = {
  securities
};
