import { FunctionComponent, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { useAppSelector } from '../../../shared/hooks/redux-hooks';
import SecurityCategoryTabs from '../../../shared/ui/SecurityCategoryTabs';
import { Security } from '../../../types/entities';
import { SecCatergory } from '../../../types/enums';
import { Logger } from '../../../utils/Logger';

import SecurityListTable from './SecurityListTable';

const SecurityListPanel: FunctionComponent = () => {
  const securityState = useAppSelector((gs) => gs.securityState);
  const { securities } = securityState;

  const [selectedCategory, setSelectedCategory] = useState<SecCatergory>(SecCatergory.Commodity);

  const filteredSecurities = useMemo(() => {
    switch (selectedCategory) {
      case SecCatergory.Commodity:
        return securities.filter((x) => x.category === 'Commodity');
      case SecCatergory.MicroCommodity:
        return securities.filter((x) => x.category === 'Micro');
      case SecCatergory.Stock:
        return securities.filter((x) => x.category === 'Stock');
    }
  }, [securities, selectedCategory]);

  const handleSecuritySelected = (sec: Security) => {
    Logger.log(sec);
  };

  return (
    <Box>
      <SecurityCategoryTabs category={selectedCategory} onSelectChange={setSelectedCategory} />
      <SecurityListTable securities={filteredSecurities} onSecuritySelected={handleSecuritySelected} />
    </Box>
  );
};

export default SecurityListPanel;
