import React, { FunctionComponent, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import { CandleStickBar, ChartTrendLine } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { themeColors } from '../../../utils/color-utils';
import { formatNum } from '../../../utils/currency-utils';

interface Props {
  bars: CandleStickBar[];
  barSize: BarSize;
  title?: string;
  entry?: number;
  strike?: number;
  action?: string;
  onChartMouseClick?: (bar: CandleStickBar) => void;
  trendLines?: ChartTrendLine[] | undefined;
}

const BarChart: FunctionComponent<Props> = ({
  title = 'Candlestick',
  bars,
  barSize,
  entry,
  strike,
  action,
  onChartMouseClick,
  trendLines = []
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBarClick = (event: any, chartContext: any, config: any) => {
    const index = config.seriesIndex !== -1 ? config.seriesIndex : 0;
    // console.log(config.config.series[config.seriesIndex]);
    // console.log(config.config.series[config.seriesIndex].name);
    // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex]);

    const bar: CandleStickBar = config.config.series[index].data[config.dataPointIndex];
    if (onChartMouseClick) {
      onChartMouseClick(bar);
    }
  };

  const options: ApexOptions = {
    theme: {
      mode: 'dark'
    },
    chart: {
      background: 'inherit',
      events: {
        click: handleBarClick
      }
    },
    title: {
      text: title,
      align: 'left'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true
      }
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        formatter: function (value) {
          return `${formatNum(value)}`;
        }
      }
    }
  };

  const barData = useMemo(() => {
    if (!bars || bars.length === 0) {
      return [];
    }
    return bars
      .filter((bar) => !!bar.x)
      .map((bar) => ({
        x: new Date(bar.x),
        y: bar.y
      }));
  }, [bars, bars?.length]);

  const candleStickSeries: ApexAxisChartSeries = [
    {
      name: 'Bars',
      color: 'green',
      type: 'candlestick',
      data: barData
    }
  ];

  const createHorizontalLine = (name: string, amount: number, color: string) => {
    const firstBar = barData[0];
    const lastBar = barData[barData.length - 1];

    return {
      name,
      type: 'line',
      color,
      data: [
        {
          x: firstBar.x,
          y: amount
        },
        {
          x: lastBar.x,
          y: amount
        }
      ]
    };
  };

  if (trendLines.length > 0 && barData.length > 0) {
    for (let i = 0; i < trendLines.length; i++) {
      const trendLineObj = trendLines[i];
      if (trendLineObj.hidden === true) {
        continue;
      }
      if (trendLineObj.specificOnly && trendLineObj.specificBarSize !== barSize) {
        continue;
      }
      const trendLine = createHorizontalLine(`TL${i + 1}`, trendLineObj.price, trendLineObj.color);
      candleStickSeries.push(trendLine);
    }
  }

  if (entry && barData.length > 0) {
    const entryLine = createHorizontalLine('Entry', entry, '#00bfff');
    candleStickSeries.push(entryLine);
  }

  if (strike && barData.length > 0) {
    let color = '#ffbf00';
    if (action) {
      color = action === 'BUY' ? themeColors.indicatorGreen : themeColors.indicatorRed;
    }
    const strikeLine = createHorizontalLine('Strike', strike, color);
    candleStickSeries.push(strikeLine);
  }

  return (
    <Box>
      <Typography component="div">
        <ReactApexChart options={options} series={candleStickSeries} type="candlestick" />
      </Typography>
    </Box>
  );
};

export default BarChart;
