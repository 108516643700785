import React, { FunctionComponent } from 'react';

import { Box, Container, Tab, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CustomTabPanel } from '../../shared/panels/CustomTabPanel';
import ResponsiveContainer from '../../shared/ui/ResponsiveContainer';
import WrappingTabs from '../../shared/ui/WrappingTabs';

import FundamentalNoteListPanel from './FundamentalNoteListPanel';
import SecurityListPanel from './SecurityListPanel';

const BackOffice: FunctionComponent = () => {
  const toTabIndex = (tabValue: string) => {
    switch (tabValue.toLowerCase()) {
      case 'fundamentals':
        return 1;
      default:
        return 0;
    }
  };

  const fromTabIndex = (index: number) => {
    switch (index) {
      case 1:
        return 'fundamentals';
      default:
        return 'securities';
    }
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab') ?? '';
  const tabIndex = toTabIndex(selectedTab);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    const tabValue = fromTabIndex(newValue);
    navigate(`?tab=${tabValue}`);
  };

  return (
    <div>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Back Office
        </Typography>
      </Container>
      <ResponsiveContainer>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <WrappingTabs value={tabIndex} onChange={handleChange}>
              <Tab label="Securities" />
              <Tab label="Fundamentals" />
            </WrappingTabs>
          </Box>
        </Box>
      </ResponsiveContainer>

      <Container sx={{ mt: 3, mb: 3 }}>
        <CustomTabPanel value={tabIndex} index={0}>
          <Typography variant="h5">Securities</Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <Typography variant="h5">Fundamentals</Typography>
        </CustomTabPanel>
      </Container>

      <ResponsiveContainer>
        <CustomTabPanel value={tabIndex} index={0}>
          <SecurityListPanel />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <FundamentalNoteListPanel />
        </CustomTabPanel>
      </ResponsiveContainer>
    </div>
  );
};

export default BackOffice;
