import { FunctionComponent } from 'react';

import { Alert, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import raceCarGreenIcon from '../../../../../../assets/svg/race-car-green.svg';
import raceCarRedIcon from '../../../../../../assets/svg/race-car-red.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks/redux-hooks';
import { useConfirm } from '../../../../../../shared/hooks/useConfirm';
import Spinner from '../../../../../../shared/ui/Spinner';
import { cancelOpenOrderAsync } from '../../../../../../store/open-order/service';
import { placeCloseOrderAsync } from '../../../../../../store/order-underlying/service';
import { Position } from '../../../../../../types/entities';
import { formatNum, setDecimalSpaces, setThousenSeparator } from '../../../../../../utils/currency-utils';
import { getUnderlyingLossPct, getUnderlyingProfitPct } from '../PositionSummary/utils';

interface Props {
  position: Position;
}

const UnderlyingPositionDetails: FunctionComponent<Props> = ({ position }: Props) => {
  const [confirm, ConfirmDialog] = useConfirm();
  const { loading } = useAppSelector((gs) => gs.positionState);

  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const openOrder = openOrderState.orders.find((x) => x.conId === position.conId);
  const openOrderSize = openOrder?.size || 0;

  const { symbol, localSymbol, orderAction, orderType, size } = position;
  const positionIcon = orderAction === 'BUY' ? raceCarGreenIcon : raceCarRedIcon;

  const unrealizedPnl = position.unrealizedPnl;
  const unrealizedPnlFormatted = unrealizedPnl ? `$${setThousenSeparator(unrealizedPnl.toFixed(0))}` : '-';
  let unrealizedPnlColor = 'Inherit';
  if (unrealizedPnl && unrealizedPnl !== 0) {
    unrealizedPnlColor = unrealizedPnl > 0 ? '#00a86b' : '#e52b50';
  }

  const realizedPnl = position?.realizedPnl;
  const realizedPnlFormatted = realizedPnl ? `$${setThousenSeparator(realizedPnl.toFixed(0))}` : '-';
  let realizedPnlColor = 'Inherit';
  if (realizedPnl && realizedPnl !== 0) {
    realizedPnlColor = realizedPnl > 0 ? '#00a86b' : '#e52b50';
  }

  let pnlPctFormatted = '-';
  let pnlPctColor = 'Inherit';
  const profitPct = getUnderlyingProfitPct(position);
  const lossPct = getUnderlyingLossPct(position);
  if (profitPct && profitPct > 0) {
    pnlPctFormatted = `${setDecimalSpaces(profitPct, 0)}%`;
    pnlPctColor = '#00a86b';
  } else if (lossPct && lossPct > 0) {
    pnlPctFormatted = `${setDecimalSpaces(lossPct, 0)}%`;
    pnlPctColor = '#e52b50';
  }

  const entryPriceFormated = !!position.entryPrice ? `${formatNum(position.entryPrice)}` : '-';
  const currentPriceFormated = !!position.marketPrice ? `${formatNum(position.marketPrice)}` : '-';

  const dispatch = useAppDispatch();

  const handleCloseClick = async () => {
    if (openOrder) {
      if (await confirm('Are you sure you want to cancel this order?')) {
        dispatch(cancelOpenOrderAsync(openOrder));
      }
    } else {
      if (await confirm('Are you sure you want to close this position?')) {
        dispatch(placeCloseOrderAsync(position));
      }
    }
  };

  return (
    <>
      {openOrder && (
        <Alert severity="warning" sx={{ m: 0, p: 1, bgcolor: 'transparent' }}>
          <Typography variant="caption">ORDER PENDING</Typography>
        </Alert>
      )}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ whiteSpace: 'nowrap' }}>
              <TableCell align="center" sx={{ width: '32px' }}>
                Vehicle
              </TableCell>
              <TableCell>Sec Type</TableCell>
              <TableCell>Symbol</TableCell>
              <TableCell>Local Symbol</TableCell>
              <TableCell align="center">Action</TableCell>
              <TableCell align="center">Order Type</TableCell>
              <TableCell align="right">Entry Price</TableCell>
              <TableCell align="right">Market Price</TableCell>
              <TableCell align="right">Order Size</TableCell>
              <TableCell align="right">Pos. Size</TableCell>
              <TableCell align="right">Unrlz. PnL</TableCell>
              <TableCell align="right">PnL %</TableCell>
              <TableCell align="right">Rlz. PnL</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ whiteSpace: 'nowrap' }}>
              <TableCell align="center">
                <img src={positionIcon} width="24px" title={localSymbol} />
              </TableCell>
              <TableCell>{position.secType}</TableCell>
              <TableCell>{symbol}</TableCell>
              <TableCell>{localSymbol}</TableCell>
              <TableCell align="center">{orderAction}</TableCell>
              <TableCell align="center">{orderType}</TableCell>
              <TableCell align="right">{entryPriceFormated}</TableCell>
              <TableCell align="right">{currentPriceFormated}</TableCell>
              <TableCell align="right">{openOrderSize}</TableCell>
              <TableCell align="right">{size}</TableCell>
              <TableCell align="right" sx={{ color: unrealizedPnlColor }}>
                {unrealizedPnlFormatted}
              </TableCell>
              <TableCell align="right" sx={{ color: pnlPctColor }}>
                {pnlPctFormatted}
              </TableCell>
              <TableCell align="right" sx={{ color: realizedPnlColor }}>
                {realizedPnlFormatted}
              </TableCell>
              <TableCell align="right">
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={handleCloseClick}
                  disabled={openOrderSize === 0 && size === 0}
                >
                  {openOrderSize !== 0 ? 'Cancel Order' : 'Close Position'}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Spinner loading={loading} />
      <ConfirmDialog />
    </>
  );
};

export default UnderlyingPositionDetails;
