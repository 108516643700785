import { FunctionComponent } from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { GRAPH_PAGE_HOUR_CHART_ID } from '../../../../../assets/constants';
import { useAppDispatch } from '../../../../../shared/hooks/redux-hooks';
import BarChartPanel from '../../../../../shared/panels/BarChartPanel';
import { setSelectedPosition } from '../../../../../store/dashboard/reducer';
import { Position } from '../../../../../types/entities';
import { BarSize } from '../../../../../types/enums';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

const GraphDialog: FunctionComponent<Props> = ({ isOpen, onClose, position }: Props) => {
  const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';
  const conId = isUnderlying ? position.conId : position.underConId;
  const symbol = isUnderlying ? position.symbol : position.underSymbol;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGotoGraphPage = () => {
    dispatch(setSelectedPosition(position));
    navigate(`/graph`);
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xl" onClose={onClose}>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ mt: 2 }}>
          <BarChartPanel
            barChartId={GRAPH_PAGE_HOUR_CHART_ID}
            conId={conId}
            exchange={position.exchange}
            title={`${symbol} - ${position.localSymbol}`}
            strikePrice={position.strikePrice}
            entryPrice={position.entryPrice}
            action={position.orderAction}
            initialBarSize={BarSize._1_HR}
            initialDuration={5}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGotoGraphPage}>
          Goto <OpenInNewIcon sx={{ ml: 1, fontSize: 16 }} />
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GraphDialog;
