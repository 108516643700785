import { FunctionComponent } from 'react';

import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import WizardNav from '../../../shared/wizard/WizardNav';
import WizardStep from '../../../shared/wizard/WizardStep';
import { reset, setActiveStep, setNavDirection } from '../../../store/order-underlying/reducer';

import MarginOverviewStep from './MarginOverviewStep';
import OrderTypeStep from './OrderTypeStep';
import PlaceOrderStep from './PlaceOrderStep';
import SelectContractStep from './SelectContractStep';
import SelectSecurityStep from './SelectSecurityStep';

const UnderlyingOrder: FunctionComponent = () => {
  const orderUnderlyingState = useAppSelector((gs) => gs.orderUnderlyingState);
  const activeStep = orderUnderlyingState.step;
  const dispatch = useAppDispatch();

  const validateNext = (step: number) => {
    switch (step) {
      case 0:
        return !!orderUnderlyingState.security;
      case 1:
        return !!orderUnderlyingState.contractDetails;
      case 2:
        return orderUnderlyingState.orderAction !== '' && orderUnderlyingState.orderType !== '';
      default:
        return true;
    }
  };

  const handleNext = () => {
    dispatch(setActiveStep(activeStep + 1));
    dispatch(setNavDirection('FORWARD'));
  };

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
    dispatch(setNavDirection('BACKWARD'));
  };

  const handleReset = () => {
    dispatch(reset());
  };

  return (
    <>
      <WizardStep step={0} activeStep={activeStep} label="Select Underlying">
        <SelectSecurityStep />
      </WizardStep>

      <WizardStep step={1} activeStep={activeStep} label="Select Contract">
        <SelectContractStep />
      </WizardStep>

      <WizardStep step={2} activeStep={activeStep}>
        <OrderTypeStep />
      </WizardStep>

      <WizardStep step={3} activeStep={activeStep} label="Check Margins">
        <MarginOverviewStep />
      </WizardStep>

      <WizardStep step={4} activeStep={activeStep} label="Place Order">
        <PlaceOrderStep />
      </WizardStep>

      <WizardNav
        steps={5}
        activeStep={activeStep}
        validateNext={validateNext}
        next={handleNext}
        previous={handleBack}
        reset={handleReset}
      />
    </>
  );
};

export default UnderlyingOrder;
