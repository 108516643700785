import { FunctionComponent, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux-hooks';
import ContractResearchPanel from '../../../../shared/panels/ContractResearchPanel';
import { getMarketDataStateByConId } from '../../../../store/market-data/selectors';
import { setEntryPrice, setMarketPrice } from '../../../../store/order-option/reducer';
import { ContractDetails } from '../../../../types/entities';

const ContractResearchStep: FunctionComponent = () => {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  // const { entryPrice } = orderOptionState;
  const details = orderOptionState.contractDetails as ContractDetails;
  const { contract } = details;

  const marketDataState = useAppSelector((gs) => gs.marketDataState);
  const marketDataByConId = getMarketDataStateByConId(marketDataState, contract.conId);
  const { marketData } = marketDataByConId;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!marketData) {
      const { lastPrice, askPrice, bidPrice } = marketData;
      const marketPrice = lastPrice > 0 ? lastPrice : (askPrice + bidPrice) / 2;
      dispatch(setMarketPrice(marketPrice));
      dispatch(setEntryPrice(marketPrice));
    }
  }, [marketData]);

  return <ContractResearchPanel contract={contract} />;
};

export default ContractResearchStep;
