import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractDetails, OrderAction, OrderState, OrderType, PlaceOrderReport, Security, TradePreset } from '../../types/entities';

import { initialState, OrderUnderlyingState } from './state';

const orderUnderlyingSlice = createSlice({
  name: 'order-underlying',
  initialState,
  reducers: {
    reset: () => initialState,
    setActiveStep: (state: OrderUnderlyingState, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setNavDirection: (state: OrderUnderlyingState, action: PayloadAction<'FORWARD' | 'BACKWARD' | undefined>) => {
      state.direction = action.payload;
    },
    fail: (state: OrderUnderlyingState, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
      state.loading = false;
    },
    loading: (state: OrderUnderlyingState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
    },
    setSecurity: (state: OrderUnderlyingState, action: PayloadAction<Security | undefined>) => {
      state.security = action.payload;
    },
    setContractDetails: (state: OrderUnderlyingState, action: PayloadAction<ContractDetails | undefined>) => {
      state.contractDetails = action.payload;
    },
    setOrderAction: (state: OrderUnderlyingState, action: PayloadAction<OrderAction>) => {
      state.orderAction = action.payload;
    },
    setOrderType: (state: OrderUnderlyingState, action: PayloadAction<OrderType>) => {
      state.orderType = action.payload;
    },
    setMarketPrice: (state: OrderUnderlyingState, action: PayloadAction<number>) => {
      state.marketPrice = action.payload;
    },
    setEntryPrice: (state: OrderUnderlyingState, action: PayloadAction<number>) => {
      state.entryPrice = action.payload;
    },
    setOrderSize: (state: OrderUnderlyingState, action: PayloadAction<number>) => {
      state.orderSize = action.payload;
    },
    setMargin: (state: OrderUnderlyingState, action: PayloadAction<OrderState>) => {
      state.margin = action.payload;
      state.loading = false;
      state.error = undefined;
    },
    setTradePreset: (state: OrderUnderlyingState, action: PayloadAction<TradePreset>) => {
      state.tradePreset = action.payload;
    },
    setEntryOrderReport: (state: OrderUnderlyingState, action: PayloadAction<PlaceOrderReport>) => {
      state.entryOrderReport = action.payload;
      state.loading = false;
      state.error = undefined;
    },
    setCloseOrderReport: (state: OrderUnderlyingState, action: PayloadAction<PlaceOrderReport>) => {
      state.closeOrderReport = action.payload;
      state.loading = false;
      state.error = undefined;
    }
  }
});

const { actions, reducer } = orderUnderlyingSlice;
export const {
  reset,
  fail,
  loading,
  setActiveStep,
  setSecurity,
  setContractDetails,
  setOrderAction,
  setOrderType,
  setOrderSize,
  setMargin,
  setMarketPrice,
  setEntryPrice,
  setTradePreset,
  setEntryOrderReport,
  setCloseOrderReport,
  setNavDirection
} = actions;
export default reducer;
