import { FunctionComponent, useEffect } from 'react';

import { Box, Button, ButtonGroup, CircularProgress, Divider, FormControl, Grid, TextField } from '@mui/material';

import { updateDuration, updateBarSize, initialize } from '../../../store/historical-data/reducer';
import { getHistoricalDataStateByKey } from '../../../store/historical-data/selectors';
import { getHistoricalDataAsync } from '../../../store/historical-data/service';
import { getTrendLineStateByConId } from '../../../store/trend-line/selectors';
import { getTrendLinesAsync } from '../../../store/trend-line/service';
import { CandleStickBar, HistoricalDataParams } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { Logger } from '../../../utils/Logger';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';

import BarChart from './BarChart';

interface Props {
  barChartId: number;
  conId: number;
  symbol?: string;
  secType?: string;
  title: string;
  exchange: string;
  action?: string;
  entryPrice?: number;
  strikePrice?: number;
  initialBarSize?: BarSize;
  initialDuration?: number;
}

const BarChartPanel: FunctionComponent<Props> = ({
  barChartId,
  conId,
  symbol,
  secType,
  title,
  exchange,
  action,
  entryPrice,
  strikePrice,
  initialBarSize,
  initialDuration
}: Props) => {
  const historicalDataState = useAppSelector((gs) => gs.historicalDataState);
  const historicalDataStateByKey = getHistoricalDataStateByKey(barChartId, conId, historicalDataState);
  const { initialized, loaded, loading, barSize, barDurationInDays, bars } = historicalDataStateByKey;
  const trendLineState = useAppSelector((gs) => gs.trendLineState);
  const { trendLines } = getTrendLineStateByConId(trendLineState, conId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize({ id: barChartId, conId, barSize: initialBarSize, duration: initialDuration }));
      dispatch(getTrendLinesAsync(conId));
    }
  }, [conId]);

  const reloadHistoryQuotesHandler = (bs?: BarSize | undefined, dur?: number | undefined) => {
    if (barSize && barDurationInDays) {
      const params: HistoricalDataParams = {
        conId,
        symbol,
        secType,
        exchange,
        barSize: bs ? bs : barSize,
        durationInDays: dur ? dur : barDurationInDays
      };
      dispatch(getHistoricalDataAsync(barChartId, params));
    }
  };

  useEffect(() => {
    if (initialized && !loaded) {
      reloadHistoryQuotesHandler();
    }
  }, [initialized]);

  const handleChartMouseClick = (bar: CandleStickBar) => {
    Logger.log(bar);
  };

  const handleSelectBarSize = (bs: BarSize) => {
    dispatch(updateBarSize({ id: barChartId, conId, barSize: bs }));
    reloadHistoryQuotesHandler(bs);
  };

  const handleChangeBarDurationInDays = (days: number) => {
    dispatch(updateDuration({ id: barChartId, conId, duration: days }));
    reloadHistoryQuotesHandler(undefined, days);
  };

  return (
    <Box>
      <BarChart
        title={title}
        barSize={barSize}
        bars={bars}
        entry={entryPrice}
        strike={strikePrice}
        action={action}
        onChartMouseClick={handleChartMouseClick}
        trendLines={trendLines}
      />
      <Box sx={{ p: 1 }}>
        <Grid item container spacing={2} xs={12} alignItems="center">
          <Grid item xs={8}>
            <ButtonGroup variant="outlined" fullWidth>
              <Button onClick={() => handleSelectBarSize(BarSize._15_MIN)} variant={barSize === BarSize._15_MIN ? 'contained' : 'outlined'}>
                15M
              </Button>
              <Button onClick={() => handleSelectBarSize(BarSize._1_HR)} variant={barSize === BarSize._1_HR ? 'contained' : 'outlined'}>
                HOUR
              </Button>
              <Button onClick={() => handleSelectBarSize(BarSize._1_DAY)} variant={barSize === BarSize._1_DAY ? 'contained' : 'outlined'}>
                DAY
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                size="small"
                label="Duration In Days"
                variant="outlined"
                type="number"
                value={barDurationInDays}
                onChange={(e) => handleChangeBarDurationInDays(Number(e.target.value))}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Divider sx={{ my: 1.5 }} />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
          <Button variant="outlined" onClick={() => reloadHistoryQuotesHandler()}>
            Reload Chart {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BarChartPanel;
