import { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

import KeyIndicator from '../../../../../shared/ui/KeyIndicator';
import { OptionContract, OrderAction } from '../../../../../types/entities';

interface Props {
  optionContract: OptionContract;
  orderAction: OrderAction;
}

const OptionKeyIndicatorPanel: FunctionComponent<Props> = ({ optionContract, orderAction }: Props) => {
  const { historicVolatility, impliedVolatility, priceDiffPct } = optionContract;
  const historicVolatilityPct = historicVolatility * 100;
  const historicVolatilityPctLabel = `${historicVolatilityPct.toFixed(2)}%`;
  const impliedVolatilityPct = impliedVolatility * 100;
  const impliedVolatilityPctLabel = `${impliedVolatilityPct.toFixed(2)}%`;
  const priceDiffPctLabel = `${priceDiffPct.toFixed(2)}%`;

  const historicVltIndicator = <KeyIndicator title="Hist Vlt" value={historicVolatilityPctLabel} />;
  const impliedVltIndicator = <KeyIndicator title="Impl Vlt" value={impliedVolatilityPctLabel} />;

  const objective = `Objective: ${orderAction === 'BUY' ? 'Reach Strike' : 'Limit to Strike'}`;

  let strikeVltColor = 'gray';
  if (orderAction === 'BUY') {
    const isHalfOrLower = priceDiffPct <= historicVolatilityPct / 2 && priceDiffPct <= impliedVolatilityPct / 2;
    if (isHalfOrLower) {
      strikeVltColor = '#00a86b';
    }
    const isEqualOrMore = priceDiffPct >= historicVolatilityPct || priceDiffPct >= impliedVolatilityPct;
    if (isEqualOrMore) {
      strikeVltColor = '#e52b50';
    }
  } else {
    const isTwiceOrMore = priceDiffPct >= historicVolatilityPct * 2 && priceDiffPct >= impliedVolatilityPct * 2;
    if (isTwiceOrMore) {
      strikeVltColor = '#00a86b';
    }
    const isEqualOrLess = priceDiffPct <= historicVolatilityPct || priceDiffPct <= impliedVolatilityPct;
    if (isEqualOrLess) {
      strikeVltColor = '#e52b50';
    }
  }

  const strikeVltIndicator = <KeyIndicator title="Strike Vlt" value={priceDiffPctLabel} valueColor={strikeVltColor} />;

  return (
    <Box>
      <Typography variant="caption" sx={{ mb: 1 }} component="div">
        {objective}
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 1 }}>
        {historicVltIndicator}
        {impliedVltIndicator}
        {strikeVltIndicator}
      </Box>
    </Box>
  );
};

export default OptionKeyIndicatorPanel;
