import * as React from 'react';

import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ContractMarketData, VolatilityHistory, OptionContract } from '../../../types/entities';

interface Props {
  marketData: ContractMarketData;
  historicalVolatility: VolatilityHistory;
  optionSelected?: OptionContract;
}

export default function VolatilityTable({ marketData, historicalVolatility, optionSelected }: Props) {
  const { lastPrice, askPrice, bidPrice, impliedVolatility } = marketData;

  const {
    low52Week,
    low26Week,
    low13Week,
    low2Week,
    low1Week,
    low30Days,
    high52Week,
    high26Week,
    high13Week,
    high2Week,
    high1Week,
    high30Days,
    volatility1Week,
    volatility2Week,
    volatility30Days
  } = historicalVolatility;

  const NOT_AVAILABLE = 'N/A';
  const getVolPct = (high: number, low: number) => {
    const avg = (high + low) * 2;
    const chg = high - low;
    if (chg === 0) {
      return NOT_AVAILABLE;
    }
    return `${((chg / avg) * 100).toFixed(2)}%`;
  };

  const getPutPriceColor = (price: number) => {
    return !!optionSelected && optionSelected.right === 'PUT' && optionSelected.strikePrice < price ? '#00a86b' : 'Inherit';
  };

  const getCallPriceColor = (price: number) => {
    return !!optionSelected && optionSelected.right === 'CALL' && optionSelected.strikePrice > price ? '#00a86b' : 'Inherit';
  };

  const vlt1WeekPct = `${(volatility1Week * 100).toFixed(2)}%`;
  const vol1WeekColorLow = getPutPriceColor(low1Week);
  const vol1WeekColorHigh = getCallPriceColor(high1Week);

  const vlt2WeekPct = `${(volatility2Week * 100).toFixed(2)}%`;
  const vol2WeekColorLow = getPutPriceColor(low2Week);
  const col2WeekColorHigh = getCallPriceColor(high2Week);

  const vlt30DaysPct = `${(volatility30Days * 100).toFixed(2)}%`;
  const vol30DaysColorLow = getPutPriceColor(low30Days);
  const vol30DaysColorHigh = getCallPriceColor(high30Days);

  const vol13Week = getVolPct(high13Week, low13Week);
  const vol13WeekColorLow = getPutPriceColor(low13Week);
  const vol13WeekColorHigh = getCallPriceColor(high13Week);

  const vol26Week = getVolPct(high26Week, low26Week);
  const vol26WeekColorLow = getPutPriceColor(low26Week);
  const vol26WeekColorHigh = getCallPriceColor(high26Week);

  const vol52Week = getVolPct(high52Week, low52Week);
  const vol52WeekColorLow = getPutPriceColor(low52Week);
  const vol52WeekColorHigh = getCallPriceColor(high52Week);

  const price = lastPrice || (askPrice + bidPrice) / 2;

  const impliedVltPct = `${(impliedVolatility * 100).toFixed(2)}%`;
  let impliedVolatiltyAmount = '-';
  let impliedHigh = '-';
  let impliedLow = '-';
  if (price > 0) {
    const amount = price * impliedVolatility;
    impliedVolatiltyAmount = `$${amount.toFixed(2)}`;
    impliedHigh = `$${(price + amount).toFixed(2)}`;
    impliedLow = `$${(price - amount).toFixed(2)}`;
  }

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Period</TableCell>
            <TableCell align="right">Low</TableCell>
            <TableCell align="right">High</TableCell>
            <TableCell align="right">Chg.</TableCell>
            <TableCell align="right">Vlt.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }} colSpan={5}>
              Historical
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1 Week</TableCell>
            <TableCell align="right" style={{ color: vol1WeekColorLow }}>
              ${low1Week.toFixed(2)}
            </TableCell>
            <TableCell align="right" style={{ color: vol1WeekColorHigh }}>
              ${high1Week.toFixed(2)}
            </TableCell>
            <TableCell align="right">${(high1Week - low1Week).toFixed(2)}</TableCell>
            <TableCell align="right">{vlt1WeekPct}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>2 Weeks</TableCell>
            <TableCell align="right" style={{ color: vol2WeekColorLow }}>
              ${low2Week.toFixed(2)}
            </TableCell>
            <TableCell align="right" style={{ color: col2WeekColorHigh }}>
              ${high2Week.toFixed(2)}
            </TableCell>
            <TableCell align="right">${(high2Week - low2Week).toFixed(2)}</TableCell>
            <TableCell align="right">{vlt2WeekPct}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>30 Days</TableCell>
            <TableCell align="right" style={{ color: vol30DaysColorLow }}>
              ${low30Days.toFixed(2)}
            </TableCell>
            <TableCell align="right" style={{ color: vol30DaysColorHigh }}>
              ${high30Days.toFixed(2)}
            </TableCell>
            <TableCell align="right">${(high30Days - low30Days).toFixed(2)}</TableCell>
            <TableCell align="right">{vlt30DaysPct}</TableCell>
          </TableRow>
          {vol13Week !== NOT_AVAILABLE && (
            <TableRow>
              <TableCell>13 Weeks</TableCell>
              <TableCell align="right" style={{ color: vol13WeekColorLow }}>
                ${low13Week.toFixed(2)}
              </TableCell>
              <TableCell align="right" style={{ color: vol13WeekColorHigh }}>
                ${high13Week.toFixed(2)}
              </TableCell>
              <TableCell align="right">${(high13Week - low13Week).toFixed(2)}</TableCell>
              <TableCell align="right">{vol13Week}</TableCell>
            </TableRow>
          )}
          {vol26Week !== NOT_AVAILABLE && (
            <TableRow>
              <TableCell>26 Weeks</TableCell>
              <TableCell align="right" style={{ color: vol26WeekColorLow }}>
                ${low26Week.toFixed(2)}
              </TableCell>
              <TableCell align="right" style={{ color: vol26WeekColorHigh }}>
                ${high26Week.toFixed(2)}
              </TableCell>
              <TableCell align="right">${(high26Week - low26Week).toFixed(2)}</TableCell>
              <TableCell align="right">{vol26Week}</TableCell>
            </TableRow>
          )}
          {vol52Week !== NOT_AVAILABLE && (
            <TableRow>
              <TableCell>52 Weeks</TableCell>
              <TableCell align="right" style={{ color: vol52WeekColorLow }}>
                ${low52Week.toFixed(2)}
              </TableCell>
              <TableCell align="right" style={{ color: vol52WeekColorHigh }}>
                ${high52Week.toFixed(2)}
              </TableCell>
              <TableCell align="right">${(high52Week - low52Week).toFixed(2)}</TableCell>
              <TableCell align="right">{vol52Week}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }} colSpan={5}>
              Implied
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>30 days</TableCell>
            <TableCell align="right">{impliedLow}</TableCell>
            <TableCell align="right">{impliedHigh}</TableCell>
            <TableCell align="right">{impliedVolatiltyAmount}</TableCell>
            <TableCell align="right">{impliedVltPct}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
