import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { RESEARCH_PAGE_DAILY_CHART_ID, RESEARCH_PAGE_HOUR_CHART_ID } from '../../../assets/constants';
import {
  setDailyGraphCollapsed,
  setHourGraphCollapsed,
  setIndicatorPanelCollapsed,
  setNewsPanelCollapsed
} from '../../../store/user-settings/reducer';
import { Contract, FavoriteContract } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import AccordionWrapper from '../AccordionWrapper';
import BarChartPanel from '../BarChartPanel';
import MarketDataPanel from '../MarketDataPanel';
import NewsPanel from '../NewsPanel';
import TrendLinePanel from '../TrendLinePanel';

import ContractHeader from './ContractHeader';

interface Props {
  contract: FavoriteContract | Contract;
  entryPrice?: number | undefined;
  strikePrice?: number | undefined;
  action?: string | undefined;
}
const ContractResearchPanel: FunctionComponent<Props> = ({ contract, entryPrice, strikePrice, action }: Props) => {
  const dispatch = useAppDispatch();
  const userSettingsState = useAppSelector((gs) => gs.userSettingsState);
  const { newsPanelCollapsed, indicatorPanelCollapsed, hourGraphCollapsed, dailyGraphCollapsed } = userSettingsState;
  const toggleNewsPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setNewsPanelCollapsed(!expanded));
  const toggleIndicatorPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setIndicatorPanelCollapsed(!expanded));
  const toggleDailyGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setDailyGraphCollapsed(!expanded));
  const toggleHourGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setHourGraphCollapsed(!expanded));

  const securityState = useAppSelector((gs) => gs.securityState);
  const securities = securityState.securities;
  const security = securities.find((x) => x.symbol === contract.symbol);

  return (
    <Box>
      {security && <ContractHeader security={security} contract={contract} />}
      <AccordionWrapper title="News" collapsed={newsPanelCollapsed} onChange={toggleNewsPanelCollapsed}>
        <NewsPanel contract={contract} />
      </AccordionWrapper>
      <AccordionWrapper title="Indicators" collapsed={indicatorPanelCollapsed} onChange={toggleIndicatorPanelCollapsed}>
        <Box sx={{ mx: 1 }}>
          <MarketDataPanel contract={contract} />
        </Box>
      </AccordionWrapper>
      <AccordionWrapper title="Daily Barchart" collapsed={dailyGraphCollapsed} onChange={toggleDailyGraphCollapsed}>
        <BarChartPanel
          barChartId={RESEARCH_PAGE_DAILY_CHART_ID}
          conId={contract.conId}
          title={contract.localSymbol}
          exchange={contract.exchange}
          initialBarSize={BarSize._1_DAY}
          initialDuration={50}
          entryPrice={entryPrice}
          strikePrice={strikePrice}
          action={action}
        />
      </AccordionWrapper>
      <AccordionWrapper title="Hour Barchart" collapsed={hourGraphCollapsed} onChange={toggleHourGraphCollapsed}>
        <BarChartPanel
          barChartId={RESEARCH_PAGE_HOUR_CHART_ID}
          conId={contract.conId}
          title={contract.localSymbol}
          exchange={contract.exchange}
          initialBarSize={BarSize._1_HR}
          initialDuration={5}
          entryPrice={entryPrice}
          strikePrice={strikePrice}
          action={action}
        />
      </AccordionWrapper>
      <AccordionWrapper title="Trend Lines" collapsed={hourGraphCollapsed} onChange={toggleHourGraphCollapsed}>
        <TrendLinePanel conId={contract.conId} />
      </AccordionWrapper>
    </Box>
  );
};

export default ContractResearchPanel;
