import { FunctionComponent } from 'react';

import { Card, Typography } from '@mui/material';

interface Props {
  title: string;
  value: string | number;
  valueColor?: string;
  footer?: string;
}

const KeyIndicator: FunctionComponent<Props> = ({ title, value, valueColor = 'inherit', footer }: Props) => {
  return (
    <Card
      raised={true}
      sx={{
        p: 0.5,
        display: 'inline-flex',
        flexDirection: 'column',
        gap: 0.5,
        minWidth: '100px',
        maxWidth: '150px'
      }}
    >
      <Typography variant="caption" sx={{ textAlign: 'center' }}>
        {title.toUpperCase()}
      </Typography>
      <Typography variant="h5" sx={{ flexGrow: 1, textAlign: 'center', color: valueColor }}>
        {value}
      </Typography>
      {footer && (
        <Typography variant="caption" sx={{ textAlign: 'right' }}>
          {footer}
        </Typography>
      )}
    </Card>
  );
};

export default KeyIndicator;
