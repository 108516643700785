import { FavoriteContract } from '../../types/entities';
import { SecCatergory } from '../../types/enums';

export interface UserSettingsState {
  useFrozen: boolean;
  ignoreMissingUnderMarketPrice: boolean;
  useSnapshot: boolean;
  newsPanelCollapsed: boolean;
  indicatorPanelCollapsed: boolean;
  hourGraphCollapsed: boolean;
  dailyGraphCollapsed: boolean;
  selectedSecCategory: SecCatergory;
  selectedFavorite?: FavoriteContract | undefined;
}

export const initialState: UserSettingsState = {
  useFrozen: false,
  ignoreMissingUnderMarketPrice: false,
  useSnapshot: false,
  newsPanelCollapsed: false,
  indicatorPanelCollapsed: false,
  hourGraphCollapsed: false,
  dailyGraphCollapsed: false,
  selectedSecCategory: SecCatergory.Commodity
};
