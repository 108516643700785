import { ContractDetails, OrderAction, OrderState, OrderType, PlaceOrderReport, Security, TradePreset } from '../../types/entities';

export interface OrderUnderlyingState {
  loading: boolean;
  error: string | undefined;
  step: number;
  direction?: 'FORWARD' | 'BACKWARD';
  security?: Security;
  contractDetails?: ContractDetails;
  margin?: OrderState;
  orderType: OrderType;
  orderAction: OrderAction;
  orderSize: number;
  marketPrice: number;
  entryPrice: number;
  tradePreset: TradePreset;
  entryOrderReport?: PlaceOrderReport;
  closeOrderReport?: PlaceOrderReport;
}

export const initialState: OrderUnderlyingState = {
  loading: false,
  error: undefined,
  step: 0,
  orderAction: '',
  orderType: '',
  orderSize: 1,
  marketPrice: 0,
  entryPrice: 0,
  tradePreset: {
    useSmallProfit: false,
    smallProfitDelayHours: 3,
    smallProfit: 1000,
    useTimeOut: false,
    timeOutDelayHours: 72
  }
};
