export class Logger {
  static debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

  public static log(output: object | string) {
    if (Logger.debugMode) {
      // eslint-disable-next-line no-console
      console.log(output);
    }
  }
}
