import { Box, Typography } from '@mui/material';

import { useAppSelector } from '../../shared/hooks/redux-hooks';
import { setThousenSeparator } from '../../utils/currency-utils';

export function CashOverviewPanel() {
  const accountInfoState = useAppSelector((gs) => gs.accountInfoState);
  const cashBalance = accountInfoState.accountInfo?.cashBalanceUsd;
  const formatedCashBalance = cashBalance ? `$${setThousenSeparator(Math.round(cashBalance))}` : '-';
  const availableFunds = accountInfoState.accountInfo?.availableFundsUsd;
  const formatedAvailableFunds = availableFunds ? `$${setThousenSeparator(Math.round(availableFunds))}` : '-';

  return (
    <Box sx={{ my: 2, textAlign: 'right' }}>
      <Typography variant="h6">
        Cash Balance: <span>{formatedCashBalance}</span>
      </Typography>
      <Typography variant="caption">
        Available Funds: <span>{formatedAvailableFunds}</span>
      </Typography>
    </Box>
  );
}
