import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { PUTCALL_RATIO_BULL_THRESHOLD, PUTCALL_RATIO_BEAR_THRESHOLD } from '../../../assets/constants';
import { ContractMarketData } from '../../../types/entities';
import { formatNum, setThousenSeparator } from '../../../utils/currency-utils';

interface Props {
  marketData: ContractMarketData;
}

export default function MarketDataTable({ marketData }: Props) {
  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Local Symbol</TableCell>
        <TableCell align="right">Price</TableCell>
        <TableCell align="right">Put Vol.</TableCell>
        <TableCell align="right">Call Vol.</TableCell>
        <TableCell align="right">Vol. Ratio</TableCell>
        <TableCell align="right">Put Opn Intr.</TableCell>
        <TableCell align="right">Call Opn Intr.</TableCell>
        <TableCell align="right">Opn Intr. Ratio</TableCell>
        <TableCell align="right">Impl Vlt.</TableCell>
      </TableRow>
    </TableHead>
  );

  const { localSymbol, putVolume, callVolume, putOpenInterest, callOpenInterest, impliedVolatility, lastPrice, bidPrice, askPrice } =
    marketData;
  const currentPrice = lastPrice > 0 ? lastPrice : (askPrice + bidPrice) / 2;
  const volPutCallRatioNum = !!putVolume && !!callVolume ? putVolume / callVolume : undefined;
  const volPutCallRatio = volPutCallRatioNum ? formatNum(volPutCallRatioNum) : 'N/A';
  let volPutCallRatioColor = 'inherit';
  if (!!volPutCallRatioNum) {
    if (volPutCallRatioNum > PUTCALL_RATIO_BEAR_THRESHOLD) {
      volPutCallRatioColor = '#e52b50';
    } else if (volPutCallRatioNum < PUTCALL_RATIO_BULL_THRESHOLD) {
      volPutCallRatioColor = '#00a86b';
    }
  }

  const openInterestPutCallRatioNum = !!putOpenInterest && !!callOpenInterest ? putOpenInterest / callOpenInterest : undefined;
  const openInterestPutCallRatio = openInterestPutCallRatioNum ? formatNum(openInterestPutCallRatioNum) : 'N/A';
  let openInterestPutCallRatioColor = 'inherit';
  if (!!openInterestPutCallRatioNum) {
    if (openInterestPutCallRatioNum > PUTCALL_RATIO_BEAR_THRESHOLD) {
      openInterestPutCallRatioColor = '#e52b50';
    } else if (openInterestPutCallRatioNum < PUTCALL_RATIO_BULL_THRESHOLD) {
      openInterestPutCallRatioColor = '#00a86b';
    }
  }

  const implicedVolatilityPct = !!impliedVolatility ? `${(impliedVolatility * 100).toFixed(2)}%` : '-';

  const marketDataRow = (
    <TableRow>
      <TableCell>{localSymbol}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
        {formatNum(currentPrice)}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
        {setThousenSeparator(putVolume)}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
        {setThousenSeparator(callVolume)}
      </TableCell>
      <TableCell sx={{ color: volPutCallRatioColor }} align="right">
        {volPutCallRatio}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
        {setThousenSeparator(putOpenInterest)}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
        {setThousenSeparator(callOpenInterest)}
      </TableCell>
      <TableCell sx={{ color: openInterestPutCallRatioColor }} align="right">
        {openInterestPutCallRatio}
      </TableCell>
      <TableCell align="right">{implicedVolatilityPct}</TableCell>
    </TableRow>
  );

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        {tableHeader}
        <TableBody>{marketDataRow}</TableBody>
      </Table>
    </TableContainer>
  );
}
