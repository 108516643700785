import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { PUTCALL_RATIO_BEAR_THRESHOLD, PUTCALL_RATIO_BULL_THRESHOLD } from '../../../assets/constants';
import { ContractMarketData } from '../../../types/entities';
import { SqueezeResult } from '../../../types/enums';
import { formatNum, setThousenSeparator } from '../../../utils/currency-utils';
import KeyIndicator from '../../ui/KeyIndicator';

interface Props {
  marketData: ContractMarketData;
}

const KeyIndicatorPanel: FunctionComponent<Props> = ({ marketData }: Props) => {
  const RATIO_THRESHOLD = 1.0;
  const VOLATILITY_PCT_MIN_THRESHOLD = 10;
  const VOLATILITY_PCT_MAX_THRESHOLD = 20;
  const VOLUME_THRESHOLD = 100;

  const { putVolume, callVolume, putOpenInterest, callOpenInterest, dayIndicators, impliedVolatility, volatility } = marketData;
  const {
    averageTrueRangeRatio,
    relativeStrengthIndexLongPeriod,
    relativeStrengthIndexShortPeriod,
    bollingerBandsLongPeriod,
    bollingerBandsShortPeriod,
    smaLongPeriod,
    smaShortPeriod,
    squeezeResult
  } = dayIndicators;
  const { high30Days, low30Days } = volatility;

  let dayVolumeIndicator: JSX.Element | undefined;
  if (putVolume && callVolume) {
    const sumDayVolume = putVolume + callVolume;
    const sumDayVolumeFixed = setThousenSeparator(sumDayVolume.toFixed(0));
    const sumDayVolumeColor = sumDayVolume < VOLUME_THRESHOLD ? 'gray' : 'inherit';
    dayVolumeIndicator = <KeyIndicator title="Day Vol" value={sumDayVolumeFixed} valueColor={sumDayVolumeColor} />;
  }

  let dayVolumeRatioIndicator: JSX.Element | undefined;
  if (putVolume && callVolume) {
    const dayVolumeRatioNum = putVolume / callVolume;
    const dayVolumeRatio = formatNum(dayVolumeRatioNum);
    const dayVolumeRatioColor =
      dayVolumeRatioNum > PUTCALL_RATIO_BEAR_THRESHOLD
        ? '#e52b50'
        : dayVolumeRatioNum < PUTCALL_RATIO_BULL_THRESHOLD
        ? '#00a86b'
        : 'inherit';
    dayVolumeRatioIndicator = <KeyIndicator title="Day Vol Ratio" value={dayVolumeRatio} valueColor={dayVolumeRatioColor} />;
  }

  let openInterestVolumeIndicator: JSX.Element | undefined;
  if (putOpenInterest && callOpenInterest) {
    const sumOpenInterestInThousen = (putOpenInterest + callOpenInterest) / 1000;
    const sumOpenInterestInThousenFixed = setThousenSeparator(sumOpenInterestInThousen.toFixed(0)) + 'K';
    const sumOpenInterestInThousenColor = sumOpenInterestInThousen < VOLUME_THRESHOLD ? 'gray' : 'inherit';
    openInterestVolumeIndicator = (
      <KeyIndicator title="Open Intr. Vol" value={sumOpenInterestInThousenFixed} valueColor={sumOpenInterestInThousenColor} />
    );
  }

  let openInterestRatioIndicator: JSX.Element | undefined;
  if (putOpenInterest && callOpenInterest) {
    const openInterestPutCallRatioNum = putOpenInterest / callOpenInterest;
    const openInterestPutCallRatio = formatNum(openInterestPutCallRatioNum);
    const openInterestPutCallRatioColor =
      openInterestPutCallRatioNum > PUTCALL_RATIO_BEAR_THRESHOLD
        ? '#e52b50'
        : openInterestPutCallRatioNum < PUTCALL_RATIO_BULL_THRESHOLD
        ? '#00a86b'
        : 'inherit';
    openInterestRatioIndicator = (
      <KeyIndicator title="Open Intr. Ratio" value={openInterestPutCallRatio} valueColor={openInterestPutCallRatioColor} />
    );
  }

  let histVolatilityIndicator: JSX.Element | undefined;
  if (high30Days > 0 && low30Days > 0) {
    const chg = Math.abs(high30Days - low30Days);
    const histVolPct = (chg / low30Days) * 100;
    const histVolFixed = `${histVolPct.toFixed(1)}%`;
    const histVolColor =
      histVolPct <= VOLATILITY_PCT_MIN_THRESHOLD ? 'gray' : histVolPct >= VOLATILITY_PCT_MAX_THRESHOLD ? '#ff9800' : 'inherit';
    histVolatilityIndicator = <KeyIndicator title="Hist. Vlt" value={histVolFixed} valueColor={histVolColor} />;
  }

  let implVolatilityIndicator: JSX.Element | undefined;
  if (impliedVolatility) {
    const implVolFixed = `${(impliedVolatility * 100).toFixed(1)}%`;
    const impliedVolatilityPct = impliedVolatility * 100;
    const implVolColor =
      impliedVolatilityPct <= VOLATILITY_PCT_MIN_THRESHOLD
        ? 'gray'
        : impliedVolatilityPct >= VOLATILITY_PCT_MAX_THRESHOLD
        ? '#ff9800'
        : 'inherit';
    implVolatilityIndicator = <KeyIndicator title="Impl. Vlt" value={implVolFixed} valueColor={implVolColor} />;
  }

  let atrRatioIndicator: JSX.Element | undefined;
  if (averageTrueRangeRatio) {
    const averageTrueRangeRatioFixed = averageTrueRangeRatio.toFixed(2);
    const averageTrueRangeRatioColor =
      averageTrueRangeRatio > RATIO_THRESHOLD ? 'gray' : averageTrueRangeRatio < RATIO_THRESHOLD ? '#ff9800' : 'inherit';
    atrRatioIndicator = <KeyIndicator title="ATR Ratio" value={averageTrueRangeRatioFixed} valueColor={averageTrueRangeRatioColor} />;
  }

  //
  let squeezIndicator: JSX.Element | undefined;
  if (squeezeResult !== SqueezeResult.NA) {
    const squeezeColor = squeezeResult === SqueezeResult.Outside ? '#ff9800' : 'inherit';
    atrRatioIndicator = (
      <KeyIndicator title="BB/KC" value={squeezeResult === SqueezeResult.Outside ? 'OUT' : 'IN'} valueColor={squeezeColor} />
    );
  }

  let smaIndicator: JSX.Element | undefined;
  if (smaLongPeriod && smaShortPeriod) {
    const smaFixed = `${smaLongPeriod.toFixed(Math.abs(smaLongPeriod) < 10 ? 1 : 0)} / ${smaShortPeriod.toFixed(
      Math.abs(smaShortPeriod) < 10 ? 1 : 0
    )}`;
    const change = smaShortPeriod - smaLongPeriod;
    const smaColor = change > 0 ? '#00a86b' : change < 0 ? '#e52b50' : 'inherit';
    smaIndicator = <KeyIndicator title="SMA 30/8" value={smaFixed} valueColor={smaColor} />;
  }

  let rsiIndicator: JSX.Element | undefined;
  if (relativeStrengthIndexLongPeriod && relativeStrengthIndexShortPeriod) {
    const rsiFixed = `${relativeStrengthIndexLongPeriod} / ${relativeStrengthIndexShortPeriod}`;
    const change = relativeStrengthIndexShortPeriod - relativeStrengthIndexLongPeriod;
    const rsiColor = change > 0 ? '#00a86b' : change < 0 ? '#e52b50' : 'inherit';
    rsiIndicator = <KeyIndicator title="RSI 30/8" value={rsiFixed} valueColor={rsiColor} />;
  }

  let bbIndicator: JSX.Element | undefined;
  if (bollingerBandsLongPeriod && bollingerBandsShortPeriod) {
    const bbFixed = `${bollingerBandsLongPeriod.toFixed(1)} / ${bollingerBandsShortPeriod.toFixed(1)}`;
    const change = bollingerBandsShortPeriod - bollingerBandsLongPeriod;
    const bbColor = change > 0 ? '#00a86b' : change < 0 ? '#e52b50' : 'inherit';
    bbIndicator = <KeyIndicator title="BB 30/8" value={bbFixed} valueColor={bbColor} />;
  }

  return (
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 1 }}>
      {dayVolumeIndicator}
      {dayVolumeRatioIndicator}
      {openInterestVolumeIndicator}
      {openInterestRatioIndicator}
      {/* <Divider orientation="vertical" /> */}
      {histVolatilityIndicator}
      {implVolatilityIndicator}
      {atrRatioIndicator}
      {squeezIndicator}
      {/* <Divider orientation="vertical" /> */}
      {bbIndicator}
      {smaIndicator}
      {rsiIndicator}
    </Box>
  );
};

export default KeyIndicatorPanel;
