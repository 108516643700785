import { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import ContractResearchPanel from '../../../shared/panels/ContractResearchPanel';
import GutterBox from '../../../shared/ui/GutterBox';
import { setSelectedCategory, setSelectedFavorite } from '../../../store/user-settings/reducer';
import { FavoriteContract } from '../../../types/entities';
import { SecCatergory } from '../../../types/enums';

import FavoriteListPanel from './FavoriteListPanel';
import GotoOrderButton from './GotoOrderButton';

const FavoritesTab: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const userSettingsState = useAppSelector((gs) => gs.userSettingsState);
  const { selectedSecCategory, selectedFavorite } = userSettingsState;
  const onCategorySelected = (cat: SecCatergory) => dispatch(setSelectedCategory(cat));
  const onFavoriteSelected = (contract: FavoriteContract | undefined) => dispatch(setSelectedFavorite(contract));

  return (
    <div>
      <GutterBox sx={{ my: 3 }}>
        <Typography variant="h6">Browse Favorites</Typography>
      </GutterBox>
      <FavoriteListPanel
        selectedCategory={selectedSecCategory}
        onCategorySelected={onCategorySelected}
        selectedFavorite={selectedFavorite}
        onFavoriteSelected={onFavoriteSelected}
      />
      {selectedFavorite && (
        <>
          <ContractResearchPanel contract={selectedFavorite} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <GotoOrderButton favorite={selectedFavorite}></GotoOrderButton>
          </Box>
        </>
      )}
    </div>
  );
};

export default FavoritesTab;
