import { FunctionComponent } from 'react';

import { Position } from '../../../../../../types/entities';

import OptionPositionDetails from './OptionPositionDetails';
import UnderlyingPositionDetails from './UnderlyingPositionDetails';

interface Props {
  position: Position;
}

const PositionDetails: FunctionComponent<Props> = ({ position }) => {
  const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';
  return isUnderlying ? <UnderlyingPositionDetails position={position} /> : <OptionPositionDetails position={position} />;
};

export default PositionDetails;
