import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FavoriteContract } from '../../types/entities';
import { SecCatergory } from '../../types/enums';

import { UserSettingsState, initialState } from './state';

const userSlice = createSlice({
  name: 'user-settings',
  initialState,
  reducers: {
    setUseFrozen: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.useFrozen = action.payload;
    },
    setIgnoreMissingUnderMarketPrice: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.ignoreMissingUnderMarketPrice = action.payload;
    },
    setUseSnapshot: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.useSnapshot = action.payload;
    },
    setNewsPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.newsPanelCollapsed = action.payload;
    },
    setIndicatorPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.indicatorPanelCollapsed = action.payload;
    },
    setHourGraphCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.hourGraphCollapsed = action.payload;
    },
    setDailyGraphCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.dailyGraphCollapsed = action.payload;
    },
    setSelectedCategory: (state: UserSettingsState, action: PayloadAction<SecCatergory>) => {
      state.selectedSecCategory = action.payload;
    },
    setSelectedFavorite: (state: UserSettingsState, action: PayloadAction<FavoriteContract | undefined>) => {
      state.selectedFavorite = action.payload;
    }
  }
});

const { actions, reducer } = userSlice;
export const {
  setUseFrozen,
  setIgnoreMissingUnderMarketPrice,
  setUseSnapshot,
  setNewsPanelCollapsed,
  setIndicatorPanelCollapsed,
  setHourGraphCollapsed,
  setDailyGraphCollapsed,
  setSelectedCategory,
  setSelectedFavorite
} = actions;
export default reducer;
