import { FunctionComponent, useMemo } from 'react';

import { Box, Button, Card, Typography } from '@mui/material';

import { useAppSelector } from '../../../shared/hooks/redux-hooks';
import SecurityCategoryTabs from '../../../shared/ui/SecurityCategoryTabs';
import { FavoriteContract, Security } from '../../../types/entities';
import { SecCatergory } from '../../../types/enums';

interface Props {
  selectedCategory: SecCatergory;
  onCategorySelected: (cat: SecCatergory) => void;
  selectedFavorite: FavoriteContract | undefined;
  onFavoriteSelected: (fav: FavoriteContract | undefined) => void;
}

const FavoriteListPanel: FunctionComponent<Props> = ({ selectedCategory, onCategorySelected, selectedFavorite, onFavoriteSelected }) => {
  const { securities } = useAppSelector((gs) => gs.securityState);
  const { favorites } = useAppSelector((gs) => gs.favoriteContractState);

  const handleClick = (fav: FavoriteContract) => {
    if (selectedFavorite?.conId === fav.conId) {
      onFavoriteSelected(undefined);
    } else {
      onFavoriteSelected(fav);
    }
  };

  const getFavoritesWithSecurity = () => {
    const list: { fav: FavoriteContract; sec: Security | undefined }[] = [];
    for (let i = 0; i < favorites.length; i++) {
      list.push({
        fav: favorites[i],
        sec: securities.find((x) => x.symbol === favorites[i].symbol)
      });
    }
    return list;
  };

  const filterByCategory = (newValue: SecCatergory) => {
    const list = getFavoritesWithSecurity();
    switch (newValue) {
      case SecCatergory.Commodity:
        return list.filter((x) => x.sec?.category === 'Commodity').map((x) => x.fav);
      case SecCatergory.MicroCommodity:
        return list.filter((x) => x.sec?.category === 'Micro').map((x) => x.fav);
      case SecCatergory.Stock:
        return list.filter((x) => x.sec?.category === 'Stock').map((x) => x.fav);
    }
  };

  const sortByName = (a: FavoriteContract, b: FavoriteContract) => (a.securityName > b.securityName ? 1 : -1);

  const handleCategoryTabClick = (cat: SecCatergory) => {
    onFavoriteSelected(undefined);
    onCategorySelected(cat);
  };

  const favoritesChips = useMemo(() => {
    const list = filterByCategory(selectedCategory);
    return list.sort(sortByName).map((x, i) => {
      return (
        <Button
          key={i}
          onClick={() => handleClick(x)}
          variant={x.conId === selectedFavorite?.conId ? 'contained' : 'outlined'}
        >{`${x.securityName} - ${x.localSymbol}`}</Button>
      );
    });
  }, [favorites, selectedCategory, selectedFavorite]);

  return (
    <Card sx={{ mb: 2, p: 2 }}>
      <Typography sx={{ pb: 0.5 }} variant="caption" component="div">
        FAVORITE CONTRACTS
      </Typography>
      <SecurityCategoryTabs category={selectedCategory} onSelectChange={handleCategoryTabClick} />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>{favoritesChips}</Box>
    </Card>
  );
};

export default FavoriteListPanel;
