import { FunctionComponent } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import RaceTrack, { RaceDriver, RaceSetup } from '../../../../../../shared/panels/RaceTrack';
import { Position } from '../../../../../../types/entities';
import { formatNum, setDecimalSpaces } from '../../../../../../utils/currency-utils';

import { getRaceDuractionPct, getUnderlyingLossPct, getUnderlyingProfitPct, getUnderlyingRaceDriver } from './utils';

interface Props {
  position: Position;
}

const UnderlyingPositionSummary: FunctionComponent<Props> = ({ position }: Props) => {
  const { marketPrice } = position;

  const durationPct = getRaceDuractionPct(position);

  const raceSetup: RaceSetup = {
    durationPct,
    lossAreaPct: 50
  };

  const currentPriceFormatted = formatNum(marketPrice);
  const lossPct = getUnderlyingLossPct(position);
  const lossPctFormated = lossPct ? `${setDecimalSpaces(lossPct, 0)}%` : '-';
  const profitPct = getUnderlyingProfitPct(position);
  const profitPctFormated = profitPct ? `${setDecimalSpaces(profitPct, 0)}%` : '-';

  const underlyingDriver = getUnderlyingRaceDriver(position, profitPct, lossPct);
  const drivers: RaceDriver[] = [];
  if (underlyingDriver) {
    drivers.push(underlyingDriver);
  }

  return (
    <Box width="100%" sx={{ p: 1 }}>
      <RaceTrack drivers={drivers} setup={raceSetup} />
      <Grid container width="100%" sx={{ mt: 1 }}>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: '#e52b50' }}>
            {lossPctFormated}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[200] }}>
            {currentPriceFormatted}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: '#00a86b' }}>
            {profitPctFormated}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnderlyingPositionSummary;
