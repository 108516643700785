import { ContractDetails, OptionContract, OptionRight, OrderAction, OrderType, Position, TradePreset } from '../types/entities';

// TODO: Fix option-chain call to fetch all fields in position
export const createOptionPosition = (
  option: OptionContract,
  optionRight: OptionRight,
  orderAction: OrderAction,
  orderType: OrderType,
  orderSize: number,
  entryPrice: number,
  marketPrice: number,
  tradePreset: TradePreset
): Position => {
  const pos: Position = {
    id: '',
    groupId: '',
    sortOrder: 0,
    completed: false,
    lockPnl: false,
    isMarketClosed: false,

    /* Order */
    orderType,
    orderAction,
    entryDate: new Date(),
    entryPrice: entryPrice,
    orderSize,
    filledSize: 0,
    strikePrice: option.strikePrice,
    premium: option.premium,
    right: optionRight,

    /* Portfolio */
    size: 0,
    marketPrice,
    marketValue: 0,
    unrealizedPnl: 0,
    realizedPnl: 0,
    underMarketPrice: 0,
    portfolioRlzPnl: 0,

    /* schedule */
    useSmallProfit: tradePreset.useSmallProfit,
    smallProfitDelayHours: tradePreset.smallProfitDelayHours,
    smallProfit: tradePreset.smallProfit,
    useTimeOut: tradePreset.useTimeOut,
    timeOutDelayHours: tradePreset.timeOutDelayHours,

    /* contract */
    conId: option.conId,
    symbol: option.symbol,
    localSymbol: option.localSymbol,
    secType: option.secType,
    exchange: option.exchange,
    timeZone: '',
    expiration: '',
    lastTradeTime: '',
    expirationDate: new Date(option.expires),

    /* underlying contract */
    underConId: option.underConId,
    underSymbol: option.underSymbol,
    underSecType: option.underSecType,
    multiplier: option.multiplier,
    tickIncrement: option.tickIncrement,
    priceMagnifier: option.priceMagnifier
  };

  return pos;
};

export const createUnderlyingPosition = (
  contractDetails: ContractDetails,
  orderAction: OrderAction,
  orderType: OrderType,
  orderSize: number,
  entryPrice: number,
  marketPrice: number,
  tradePreset: TradePreset
): Position => {
  const contract = contractDetails.contract;

  const pos: Position = {
    id: '',
    groupId: '',
    sortOrder: 0,
    completed: false,
    lockPnl: false,
    isMarketClosed: false,

    /* Order */
    orderType,
    orderAction,
    entryDate: new Date(),
    entryPrice: entryPrice,
    orderSize,
    filledSize: 0,
    strikePrice: 0,
    premium: 0,

    /* Portfolio */
    size: 0,
    marketPrice,
    marketValue: 0,
    unrealizedPnl: 0,
    realizedPnl: 0,
    underMarketPrice: 0,
    portfolioRlzPnl: 0,

    /* schedule */
    useSmallProfit: tradePreset.useSmallProfit,
    smallProfitDelayHours: tradePreset.smallProfitDelayHours,
    smallProfit: tradePreset.smallProfit,
    useTimeOut: tradePreset.useTimeOut,
    timeOutDelayHours: tradePreset.timeOutDelayHours,

    /* contract */
    conId: contract.conId,
    symbol: contract.symbol,
    localSymbol: contract.localSymbol,
    secType: contract.secType,
    exchange: contract.exchange,
    timeZone: contractDetails.timeZoneId,
    expiration: contractDetails.realExpirationDate,
    lastTradeTime: contractDetails.lastTradeTime,

    /* underlying contract */
    underConId: contractDetails.underConId,
    underSymbol: contractDetails.underSymbol,
    underSecType: contractDetails.underSecType,
    multiplier: contract.multiplier,
    tickIncrement: contractDetails.minTick,
    priceMagnifier: contractDetails.priceMagnifier
  };

  return pos;
};
