import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { Position } from '../../../../types/entities';

import PositionBody from './PositionBody';
import PositionHeader from './PositionHeader';

interface Props {
  position: Position;
  bg: string;
}

const PositionPanel: FunctionComponent<Props> = ({ position, bg }: Props) => {
  return (
    <Box sx={{ backgroundColor: bg }}>
      <PositionHeader position={position} />
      <PositionBody position={position} />
    </Box>
  );
};

export default PositionPanel;
