import * as React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import GutterBox from '../../../shared/ui/GutterBox';
import {
  reset as resetOptionOrder,
  setActiveStep as setActiveStepOptionOrder,
  setNavDirection as setNavDirectionOptionOrder
} from '../../../store/order-option/reducer';
import { setSecurity as setOptionOrderSecurity } from '../../../store/order-option/reducer';
import { setSecurity as setUnderlyingOrderSecurity } from '../../../store/order-underlying/reducer';
import {
  reset as resetUnderlyingOrder,
  setActiveStep as setActiveStepUnderlyingOrder,
  setNavDirection as setNavDirectionUnderlyingOrder
} from '../../../store/order-underlying/reducer';
import { FavoriteContract } from '../../../types/entities';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

interface Props {
  favorite: FavoriteContract;
}

const GotoOrderButton: React.FunctionComponent<Props> = ({ favorite }: Props) => {
  const { securities } = useAppSelector((gs) => gs.securityState);
  const security = securities.find((x) => x.symbol === favorite.symbol);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGotoOptionOrder = () => {
    closeMenu();
    dispatch(resetOptionOrder());
    dispatch(setOptionOrderSecurity(security));
    dispatch(setActiveStepOptionOrder(1));
    dispatch(setNavDirectionOptionOrder('FORWARD'));
    navigate('/order?tab=option');
  };

  const handleGotoUnderlyingOrder = () => {
    closeMenu();
    dispatch(resetUnderlyingOrder());
    dispatch(setUnderlyingOrderSecurity(security));
    dispatch(setActiveStepUnderlyingOrder(1));
    dispatch(setNavDirectionUnderlyingOrder('FORWARD'));
    navigate('/order?tab=underlying');
  };

  const closeMenu = () => setAnchorEl(null);

  return (
    <GutterBox>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Go To Order
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem onClick={handleGotoOptionOrder} disableRipple>
          Option
        </MenuItem>
        <MenuItem onClick={handleGotoUnderlyingOrder} disableRipple>
          Underlying
        </MenuItem>
      </StyledMenu>
    </GutterBox>
  );
};

export default GotoOrderButton;
