import React, { FunctionComponent, useEffect } from 'react';

import { Box, Button, Card } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux-hooks';
import ContractDetailsTable from '../../../../shared/panels/ContractDetailsTable';
import GutterBox from '../../../../shared/ui/GutterBox';
import Spinner from '../../../../shared/ui/Spinner';
import { setContractDetails } from '../../../../store/order-underlying/reducer';
import { getContractsByUnderlyingSymbolState } from '../../../../store/security-contract/selectors';
import { getSecurityContractsAsync } from '../../../../store/security-contract/service';
import { ContractDetails, Security } from '../../../../types/entities';

const SelectContractStep: FunctionComponent = () => {
  const orderUnderlyingState = useAppSelector((gs) => gs.orderUnderlyingState);
  const security = orderUnderlyingState.security as Security;
  const underlyingSymbol = security.symbol;
  const contractDetails = orderUnderlyingState.contractDetails;
  const selectedConId = contractDetails?.contract?.conId || 0;

  const securityContractState = useAppSelector((gs) => gs.securityContractState);
  const { loading, loaded, contracts } = getContractsByUnderlyingSymbolState(securityContractState, underlyingSymbol);

  const dispatch = useAppDispatch();

  const reloadContractMonthsHandler = () => {
    dispatch(getSecurityContractsAsync(security));
  };

  useEffect(() => {
    if (!loaded) {
      reloadContractMonthsHandler();
    }
  }, []);

  const selectContractMonthHandler = (details: ContractDetails) => {
    dispatch(setContractDetails(details));
  };

  return !loading ? (
    <Box>
      <Card sx={{ my: 2 }}>
        <ContractDetailsTable list={contracts} maxRows={6} selectedConId={selectedConId} onSelect={selectContractMonthHandler} />
      </Card>
      <GutterBox sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button size="small" variant="outlined" onClick={reloadContractMonthsHandler}>
          Reload Contracts
        </Button>
      </GutterBox>
    </Box>
  ) : (
    <Spinner loading={loading} />
  );
};

export default SelectContractStep;
