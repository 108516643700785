import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './state';

const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {}
});

const { reducer } = securitySlice;
export default reducer;
