import { RaceDriver, RaceDriverIcon } from '../../../../../../shared/panels/RaceTrack';
import { Position } from '../../../../../../types/entities';

const DEFAULT_UNDERLYING_VOLATILITY_PCT = 5;

export const getRaceDuractionPct = (position: Position) => {
  const entryDate = position.entryDate;
  const expires = position.expirationDate;
  if (!entryDate || !expires) {
    return;
  }

  const now = new Date();
  const createdDt = new Date(entryDate);
  const expiresDt = new Date(expires);
  const tsWhole = expiresDt.getTime() - createdDt.getTime();
  const tsPart = now.getTime() - createdDt.getTime();
  return (tsPart / tsWhole) * 100;
};

export const getStopLossPct = (position: Position, triggerPrice: number) => {
  const { right: optionRight, strikePrice: stopPrice } = position;
  const isPut = optionRight === 'PUT';
  const stopLossDiff = isPut ? triggerPrice - stopPrice : stopPrice - triggerPrice;
  const fullRange = getFullRangeDiff(position);
  return (stopLossDiff * 100) / fullRange;
};

export const getStrikeReflectedPrice = (pos: Position) => {
  const reflectionChange = Math.abs(pos.entryPrice - pos.strikePrice) * 2;
  if (pos.orderAction == 'SELL') {
    // limit er strike-reflected-price
    return pos.right == 'PUT' ? pos.strikePrice + reflectionChange : pos.strikePrice - reflectionChange;
  } else {
    // limit er strike-price
    return pos.right == 'PUT' ? pos.strikePrice + reflectionChange : pos.strikePrice - reflectionChange;
  }
};

export const getFullRangeDiff = (position: Position) => {
  const lmtPrice = getStrikeReflectedPrice(position);
  const { strikePrice: stopPrice } = position;
  return Math.abs(lmtPrice - stopPrice);
};

export const getOptionLossPct = (position: Position) => {
  const { unrealizedPnl } = position;
  if (unrealizedPnl >= 0) {
    return 0;
  }
  const optionPremium = position.marketValue - position.unrealizedPnl;
  return (Math.abs(unrealizedPnl) * 100) / Math.abs(optionPremium);
};

export const getUnderlyingLossPct = (position: Position) => {
  const { unrealizedPnl } = position;
  if (unrealizedPnl >= 0) {
    return 0;
  }
  const maxVolatility = (position.entryPrice * DEFAULT_UNDERLYING_VOLATILITY_PCT) / 100;
  const volatilty = Math.abs(position.marketPrice - position.entryPrice);
  return (volatilty / maxVolatility) * 100;
};

export const getOptionProfitPct = (position: Position) => {
  const { unrealizedPnl } = position;
  if (unrealizedPnl <= 0) {
    return 0;
  }
  const optionPremium = position.marketValue - position.unrealizedPnl;
  return (unrealizedPnl * 100) / Math.abs(optionPremium);
};

export const getUnderlyingProfitPct = (position: Position) => {
  const { unrealizedPnl } = position;
  if (unrealizedPnl <= 0) {
    return 0;
  }
  const maxVolatility = (position.entryPrice * DEFAULT_UNDERLYING_VOLATILITY_PCT) / 100;
  const volatilty = Math.abs(position.marketPrice - position.entryPrice);
  return (volatilty / maxVolatility) * 100;
};

export const getOptionRaceDriver = (position: Position) => {
  const { localSymbol: optionSymbol, orderAction: optionAction, right: optionRight, unrealizedPnl } = position;
  if (position.size === 0) {
    return;
  }

  //console.log(position.entryPrice, position.strikePrice, position.underMarketPrice);

  const optionPremium = Math.abs(position.marketValue - position.unrealizedPnl);

  const fullRange = optionPremium * 2;
  const progress = optionPremium + unrealizedPnl;
  const progressPct = (progress * 100) / fullRange;

  let icon: RaceDriverIcon = 'car-green';
  if (optionAction === 'BUY') {
    icon = optionRight === 'CALL' ? 'car-green' : 'car-red';
  } else {
    icon = optionRight === 'PUT' ? 'car-green' : 'car-red';
  }

  const driver: RaceDriver = {
    name: optionSymbol,
    icon: icon,
    progressPct
  };

  return driver;
};

export const getUnderlyingRaceDriver = (position: Position, profitPct: number, lossPct: number) => {
  const { localSymbol, orderAction } = position;
  if (position.size === 0) {
    return;
  }
  const fullRange = 200;
  const progress = profitPct > 0 ? 100 + profitPct : lossPct > 0 ? 100 - lossPct : 0;
  const progressPct = (progress * 100) / fullRange;
  const icon: RaceDriverIcon = orderAction === 'BUY' ? 'car-green' : 'car-red';
  const driver: RaceDriver = {
    name: localSymbol,
    icon: icon,
    progressPct
  };

  return driver;
};
