import { FunctionComponent, useMemo, useState } from 'react';

import EditNoteIcon from '@mui/icons-material/EditAttributes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { useTheme, Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';

import racingFlagsIcon from '../../../../../assets/svg/racing-flags.svg';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/redux-hooks';
import { updatePositionAsync } from '../../../../../store/position/service';
import { Position } from '../../../../../types/entities';

import EditPositionDialog from './EditPositionDialog';
import GraphDialog from './GraphDialog';
import TimelineDialog from './TimelineDialog';

interface Props {
  position: Position;
}

const PositionHeader: FunctionComponent<Props> = ({ position }) => {
  const theme = useTheme();

  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const openOrder = openOrderState.orders.find((x) => x.conId === position.conId);
  const openOrderSize = openOrder?.size || 0;

  const dispatch = useAppDispatch();

  const handleSortOrderBtnClick = (sortOrder: number) => {
    const pos: Position = { ...position, sortOrder: position.sortOrder + sortOrder };
    dispatch(updatePositionAsync(pos));
  };

  const [showGraphDialog, setShowGraphDialog] = useState(false);
  const handleGraphBtnClick = () => setShowGraphDialog(true);
  const closeGraphDialog = () => setShowGraphDialog(false);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const handleSchduleBtnClick = () => setShowScheduleDialog(true);
  const closeScheduleDialog = () => setShowScheduleDialog(false);
  const [showPositionGroupDialog, setShowPositionGroupDialog] = useState(false);
  const handleEditBtnClick = () => setShowPositionGroupDialog(true);
  const closePositionGroupDialog = () => setShowPositionGroupDialog(false);

  const hasActiveSchedule = position.useSmallProfit || position.useTimeOut;

  const { expirationDate: expires } = position;

  const hasPositionExpired = useMemo(() => {
    if (!!expires) {
      const now = new Date();
      const exDt = new Date(expires);
      return now > exDt || (openOrderSize === 0 && position.size === 0);
    }
  }, [expires]);

  const timelineButton = useMemo(() => {
    if (hasPositionExpired) {
      return (
        <Tooltip title="Timeline : Expired" placement="top">
          <IconButton size="small" onClick={handleSchduleBtnClick}>
            <img src={racingFlagsIcon} width="24px" title="Completed" alt="Completed" />
          </IconButton>
        </Tooltip>
      );
    }

    let color = theme.palette.grey[200];
    if (position.isMarketClosed) {
      color = '#ffe135';
    } else if (hasActiveSchedule) {
      color = theme.palette.primary.main;
    }

    const icon = position.isMarketClosed ? (
      <ScheduleIcon sx={{ fontSize: 20, mx: 0.5 }} />
    ) : (
      <TimelapseIcon sx={{ fontSize: 20, mx: 0.5 }} />
    );

    return (
      <Tooltip title={position.isMarketClosed ? 'Timeline: Market Closed' : 'Timeline'} placement="top">
        <IconButton size="small" onClick={handleSchduleBtnClick} sx={{ color }}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  }, [position, hasPositionExpired]);

  const isOption = position.secType === 'FOP' || position.secType === 'OPT';
  const expiresDt = expires ? new Date(expires) : undefined;
  const details = isOption && expiresDt ? dayjs(expiresDt).format('MMM DD.') : position.localSymbol;

  return (
    <>
      <Box sx={{ mx: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption">{position.symbol}</Typography>
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          <Typography variant="caption">{position.secType}</Typography>
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          <Typography variant="caption">{details}</Typography>
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          <Typography fontSize={12} fontFamily="monospace">
            {position.size > 0 ? '+' : ''}
            {position.size}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Tooltip title="Barchart" placement="top">
            <IconButton size="small" onClick={handleGraphBtnClick} sx={{ color: (theme) => theme.palette.grey[200] }}>
              <ShowChartIcon />
            </IconButton>
          </Tooltip>
          {timelineButton}
          <Divider sx={{ mx: 0.5 }} orientation="vertical" flexItem />
          <Tooltip title="Move Up" placement="top">
            <IconButton size="small" onClick={() => handleSortOrderBtnClick(1)} sx={{ color: (theme) => theme.palette.grey[200] }}>
              <KeyboardArrowUpIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Move Down" placement="top">
            <IconButton size="small" onClick={() => handleSortOrderBtnClick(-1)} sx={{ color: (theme) => theme.palette.grey[200] }}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings" placement="top">
            <IconButton size="small" onClick={handleEditBtnClick} sx={{ color: (theme) => theme.palette.grey[200] }}>
              <EditNoteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <EditPositionDialog isOpen={showPositionGroupDialog} onClose={closePositionGroupDialog} position={position} />
      <GraphDialog isOpen={showGraphDialog} onClose={closeGraphDialog} position={position} />
      <TimelineDialog isOpen={showScheduleDialog} onClose={closeScheduleDialog} position={position} />
    </>
  );
};

export default PositionHeader;
