import { FunctionComponent } from 'react';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Box, IconButton, Divider, Tooltip, Typography } from '@mui/material';

const Footer: FunctionComponent = () => {
  const version = process.env.REACT_APP_VERSION;

  const handleHardRefresh = (): void => {
    window.location.reload();
    // window.location.replace("/")
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '75vh',
        background: 'linear-gradient(to bottom, #121212, rgba(255,2555,255, 0.10))'
      }}
    >
      <Box>
        <Divider>
          <Typography variant="caption">Frontend: {`v${version}`}</Typography>
        </Divider>
        <Box sx={{ my: 3, display: 'flex', justifyContent: 'center' }}>
          <Tooltip title="Hard refresh">
            <IconButton size="small" onClick={handleHardRefresh}>
              <AutorenewIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
