import { FunctionComponent, useEffect, useState } from 'react';

import HourglassTop from '@mui/icons-material/HourglassTop';
import MenuIcon from '@mui/icons-material/Menu';
import OnlineIcon from '@mui/icons-material/PowerSettingsNew';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material';
// eslint-disable-next-line import/order
import { AxiosError } from 'axios';

import './styles.scss';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { checkBrokerConnection } from '../../../api';
import ScheduleButton from '../../ui/ScheduleButton';

interface NavItem {
  name: string;
  to: string;
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const Header: FunctionComponent<Props> = ({ window }: Props) => {
  const title = 'ORCASTRIKE';
  const navItems: NavItem[] = [
    { name: 'Dashboard', to: '/' },
    { name: 'Order', to: '/order' },
    { name: 'Trade History', to: '/trade-history' },
    { name: 'Back Office', to: '/back-office' },
    { name: 'Settings', to: '/settings' },
    { name: 'Logout', to: '/logout' }
  ];
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = useState(false);

  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const checkStatusAsync = async () => {
    setLoading(true);
    try {
      const isConnected = (await checkBrokerConnection()).data;
      setConnected(isConnected);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || error.message;
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkStatusAsync();
  }, []);

  let statusIcon: JSX.Element;
  if (loading) {
    statusIcon = <HourglassTop sx={{ color: '#ccc' }} />;
  } else if (connected) {
    statusIcon = <OnlineIcon sx={{ color: '#00a86b' }} />;
  } else {
    statusIcon = <OnlineIcon sx={{ color: '#e52b50' }} />;
  }

  const statusIconButton = (
    <IconButton color="primary" onClick={checkStatusAsync}>
      {statusIcon}
    </IconButton>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {title}
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to={item.to} className="link-item">
                <ListItemText primary={item.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            {title}
          </Typography>
          {statusIconButton}
          <ScheduleButton />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item.name}>
                <Link to={item.to} className="link-item">
                  {item.name}
                </Link>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Toolbar />
    </Box>
  );
};

export default Header;
