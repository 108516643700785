import BlockIcon from '@mui/icons-material/Block';
import { Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Security } from '../../../types/entities';

interface Props {
  securities: Security[];
  onSecuritySelected: (sec: Security) => void;
}

export default function SecurityListTable({ securities, onSecuritySelected }: Props) {
  const tableHeader = (
    <TableHead>
      <TableRow sx={{ whiteSpace: 'nowrap' }}>
        <TableCell>Security</TableCell>
        <TableCell>Symbol</TableCell>
        <TableCell>Exchange</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Type</TableCell>
        <TableCell align="right">Multiplier</TableCell>
        <TableCell align="right">Tick Incr</TableCell>
        <TableCell align="right" valign="bottom">
          <Tooltip title="Security is disabled (hidden)">
            <BlockIcon sx={{ fontSize: 20, mt: 0.5 }} />
          </Tooltip>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const sortByName = (a: Security, b: Security) => {
    if (!a || !b) {
      return 0;
    }
    return a.name > b.name ? 1 : -1;
  };

  const sortedSecurities = !!securities && securities.length > 1 ? [...securities].sort(sortByName) : securities;

  const tableRows = sortedSecurities.map((sec, index) => {
    return (
      <TableRow hover key={index} sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => onSecuritySelected(sec)}>
        <TableCell>{sec.name}</TableCell>
        <TableCell>{sec.symbol}</TableCell>
        <TableCell>{sec.exchange}</TableCell>
        <TableCell>{sec.category}</TableCell>
        <TableCell>{sec.secType}</TableCell>
        <TableCell align="right">{sec.multiplier}</TableCell>
        <TableCell align="right">{sec.tickIncrement}</TableCell>
        <TableCell align="right">{sec.disabled && <BlockIcon sx={{ color: 'red', fontSize: 16, mt: 0.5, mr: 0.25 }} />}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer>
      <Table size="small" aria-label="Security List">
        {tableHeader}
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
}
