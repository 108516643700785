import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { IndicatorData } from '../../../types/entities';

interface Props {
  dayIndicators: IndicatorData;
  hourIndicators: IndicatorData;
}

export default function VolatilityTable({ dayIndicators, hourIndicators }: Props) {
  const { relativeStrengthIndexLongPeriod: dayRsi, bollingerBandsLongPeriod: dayBb, averageTrueRangeRatio: dayAtrRatio } = dayIndicators;
  const {
    relativeStrengthIndexLongPeriod: hourRsi,
    bollingerBandsLongPeriod: hourBb,
    averageTrueRangeRatio: hourAtrRatio
  } = hourIndicators;

  const dayRsiColor = dayRsi >= 60 ? '#00a86b' : dayRsi <= 40 ? '#e52b50' : 'inherit';
  const dayBbColor = dayBb >= 1.0 ? '#00a86b' : dayBb <= -1.0 ? '#e52b50' : 'inherit';
  const dayMomentumRatio = !!dayAtrRatio ? dayAtrRatio.toFixed(2) : 'N/A';

  const hourRsiColor = hourRsi >= 60 ? '#00a86b' : hourRsi <= 40 ? '#e52b50' : 'inherit';
  const hourBbColor = hourBb >= 1.0 ? '#00a86b' : hourBb <= -1.0 ? '#e52b50' : 'inherit';
  const hourMomentumRatio = !!hourAtrRatio ? hourAtrRatio.toFixed(2) : 'N/A';

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Indicator</TableCell>
            <TableCell align="right">Period</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>RSI</TableCell>
            <TableCell align="right">30 Hours</TableCell>
            <TableCell align="right" sx={{ color: hourRsiColor }}>
              {hourRsi.toFixed(0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bollinger</TableCell>
            <TableCell align="right">30 Hours</TableCell>
            <TableCell align="right" sx={{ color: hourBbColor }}>
              {hourBb.toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ATR Ratio</TableCell>
            <TableCell align="right">30/8 Hours</TableCell>
            <TableCell align="right">{hourMomentumRatio}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>RSI</TableCell>
            <TableCell align="right">30 Days</TableCell>
            <TableCell align="right" sx={{ color: dayRsiColor }}>
              {dayRsi.toFixed(0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bollinger</TableCell>
            <TableCell align="right">30 Days</TableCell>
            <TableCell align="right" sx={{ color: dayBbColor }}>
              {dayBb.toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ATR Ratio</TableCell>
            <TableCell align="right">30/8 Days</TableCell>
            <TableCell align="right">{dayMomentumRatio}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
