import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractDetails } from '../../types/entities';

import { SecurityContractState, initialSecurityContractState } from './state';

export type SecurityContractSuccessAction = {
  symbol: string;
  futures: ContractDetails[];
};

export type SecurityContractFailedAction = {
  symbol: string;
  error: string | undefined;
};

export type SecurityContractLoadingAction = {
  symbol: string;
  loading: boolean;
};

export type SecurityContractSelectedAction = {
  symbol: string;
  selected: ContractDetails | undefined;
};

const securityContractSlice = createSlice({
  name: 'security-contract',
  initialState: initialSecurityContractState,
  reducers: {
    reset: () => initialSecurityContractState,
    loading: (state: SecurityContractState, action: PayloadAction<SecurityContractLoadingAction>) => {
      state[action.payload.symbol] = {
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        contracts: state[action.payload.symbol]?.contracts ?? []
      };
    },
    success: (state: SecurityContractState, action: PayloadAction<SecurityContractSuccessAction>) => {
      state[action.payload.symbol] = {
        error: undefined,
        loading: false,
        loaded: true,
        contracts: action.payload.futures
      };
    },
    fail: (state: SecurityContractState, action: PayloadAction<SecurityContractFailedAction>) => {
      state[action.payload.symbol] = {
        error: action.payload.error,
        loading: false,
        loaded: true,
        contracts: []
      };
    }
  }
});

const { actions, reducer } = securityContractSlice;
export const { reset, loading, success, fail } = actions;
export default reducer;
