import { FunctionComponent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import SelectSecurityPanel from '../../../shared/panels/SelectSecurityPanel';
import Spinner from '../../../shared/ui/Spinner';
import { getFundamentalNotesAsync } from '../../../store/fundamental-note/service';
import { FundamentalNote, Security } from '../../../types/entities';

import FundamentalNoteDialog from './FundamentalNoteDialog';
import FundamentalNoteTable from './FundamentalNoteTable';

const FundamentalNoteListPanel: FunctionComponent = () => {
  const { loading, loaded, notes } = useAppSelector((gs) => gs.fundamentalNoteState);
  const { securities } = useAppSelector((gs) => gs.securityState);
  const [security, setSecurity] = useState<Security | undefined>();

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState<FundamentalNote | undefined>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && !loaded) {
      dispatch(getFundamentalNotesAsync());
    }
  });

  const openDialog = (note?: FundamentalNote) => {
    setSelectedNote(note);
    setShowEditDialog(true);
  };

  const closeDialog = () => setShowEditDialog(false);

  const securityNotes = !!security ? notes.filter((x) => x.symbol === security.symbol) : [];

  const [expanded, setExpanded] = useState(true);

  return (
    <Box>
      <Spinner loading={loading} />

      <Accordion sx={{ my: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Select Security</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SelectSecurityPanel securities={securities} selected={security} onSelected={setSecurity} />
        </AccordionDetails>
      </Accordion>
      {security ? (
        <Card>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
            <Typography variant="h6">{security.name}</Typography>
            <Button size="small" variant="outlined" color="primary" onClick={() => openDialog()} title="Add">
              <AddIcon />
            </Button>
          </Box>
          <Box>
            {securityNotes.length > 0 ? (
              <FundamentalNoteTable notes={securityNotes} onOpenDialog={openDialog} />
            ) : (
              <Typography sx={{ pl: 2, pb: 2 }} variant="body2">
                No Fundamental Notes..
              </Typography>
            )}
          </Box>
        </Card>
      ) : (
        <Typography sx={{ mt: 3, ml: 2 }} fontSize="smaller">
          No security selected..
        </Typography>
      )}

      {security && <FundamentalNoteDialog security={security} isOpen={showEditDialog} onClose={closeDialog} note={selectedNote} />}
    </Box>
  );
};

export default FundamentalNoteListPanel;
