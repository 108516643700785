import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { useAppSelector } from '../../../../shared/hooks/redux-hooks';
import ContractResearchPanel from '../../../../shared/panels/ContractResearchPanel';
import { ContractDetails } from '../../../../types/entities';

import OrderTypePanel from './OrderTypePanel';

const OrderTypeStep: FunctionComponent = () => {
  const orderUnderlyingState = useAppSelector((gs) => gs.orderUnderlyingState);
  const { contract } = orderUnderlyingState.contractDetails as ContractDetails;
  const { entryPrice, orderAction } = orderUnderlyingState;

  return (
    <Box>
      <ContractResearchPanel contract={contract} entryPrice={entryPrice} action={orderAction} />
      <OrderTypePanel />
    </Box>
  );
};

export default OrderTypeStep;
