import { FunctionComponent, useState } from 'react';

import { Tab } from '@mui/material';

import { SecCatergory } from '../../types/enums';

import WrappingTabs from './WrappingTabs';

interface Props {
  onSelectChange: (cat: SecCatergory) => void;
  category?: SecCatergory | undefined;
}

const SecurityCategoryTabs: FunctionComponent<Props> = ({ category, onSelectChange }: Props) => {
  const [selectedTab, setSelectedTab] = useState<SecCatergory>(category || SecCatergory.Commodity);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: SecCatergory) => {
    setSelectedTab(newValue);
    onSelectChange(newValue);
  };

  return (
    <WrappingTabs sx={{ mb: 3 }} value={selectedTab} onChange={handleTabChange} aria-label="Security Categories">
      <Tab value={SecCatergory.Commodity} label="Commodities" />
      <Tab value={SecCatergory.MicroCommodity} label="Micro Commodities" />
      <Tab value={SecCatergory.Stock} label="Stocks" />
    </WrappingTabs>
  );
};

export default SecurityCategoryTabs;
