import { FunctionComponent, useEffect, useState } from 'react';

//import ScheduleLoadingIcon from '@mui/icons-material/HistoryToggleOff';
import HourglassTop from '@mui/icons-material/HourglassTop';
//import ScheduleIcon from '@mui/icons-material/Schedule';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { IconButton } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { isScheduleRunning, toggleScheduleRunning } from '../../api';
import { ScheduleInfo } from '../../types/entities';

const ScheduleButton: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState<ScheduleInfo>({ repeats: 0, state: '' });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkScheduleInfoAsync = async () => {
    setLoading(true);
    try {
      const res = await isScheduleRunning();
      setScheduleInfo(res.data);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || error.message;
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const toggleScheduleRunAsync = async () => {
    setLoading(true);
    try {
      const newState = (await toggleScheduleRunning()).data;
      scheduleInfo.state = newState;
      setScheduleInfo(scheduleInfo);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || error.message;
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkScheduleInfoAsync();
  }, []);

  let scheduleIcon: JSX.Element;
  if (loading) {
    scheduleIcon = <HourglassTop sx={{ color: '#ccc' }} />;
  } else if (scheduleInfo.state === 'RUNNING') {
    scheduleIcon = <TimelapseIcon sx={{ fontSize: 23, color: '#00a86b' }} />;
  } else {
    scheduleIcon = <TimelapseIcon sx={{ fontSize: 23, color: '#ccc' }} />;
  }

  const scheduleIconButton = (
    <IconButton
      color="primary"
      id="fade-button"
      aria-controls={open ? 'fade-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      {scheduleIcon}
    </IconButton>
  );

  const handleInfoClick = () => {
    checkScheduleInfoAsync();
    handleClose();
  };

  const handleToggleClick = () => {
    toggleScheduleRunAsync();
    handleClose();
  };

  return (
    <div>
      {scheduleIconButton}
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleInfoClick}>Check Info</MenuItem>
        <MenuItem onClick={handleToggleClick}>Toggle Run</MenuItem>
      </Menu>
    </div>
  );
};

export default ScheduleButton;
