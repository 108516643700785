import { FunctionComponent } from 'react';

import { Alert, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../shared/hooks/redux-hooks';
import ContractResearchPanel from '../shared/panels/ContractResearchPanel';
import GutterBox from '../shared/ui/GutterBox';
import ResponsiveContainer from '../shared/ui/ResponsiveContainer';
import { FavoriteContract } from '../types/entities';

export const GraphPage: FunctionComponent = () => {
  const { securities } = useAppSelector((gs) => gs.securityState);

  const dashboardState = useAppSelector((gs) => gs.dashboardState);
  const { selected: position } = dashboardState;
  const navigate = useNavigate();

  let contractResearchPanel: JSX.Element | undefined;
  if (position) {
    const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';
    const conId = isUnderlying ? position.conId : position.underConId;
    const security = securities.find((x) => x.symbol === position.symbol);

    const contract = {
      conId,
      securityName: security?.name || '',
      symbol: position.symbol,
      localSymbol: position.localSymbol,
      exchange: position.exchange,
      currency: security?.currency || 'USD',
      secType: position.secType
    } as FavoriteContract;

    contractResearchPanel = (
      <Box sx={{ mt: 2 }}>
        <ContractResearchPanel
          contract={contract}
          entryPrice={position.entryPrice}
          strikePrice={position.strikePrice}
          action={position.orderAction}
        />
      </Box>
    );
  }

  return (
    <ResponsiveContainer>
      <GutterBox sx={{ my: 3 }}>
        <Button variant="outlined" sx={{ mt: 2 }} onClick={() => navigate('/positions')}>
          Go Back
        </Button>
      </GutterBox>
      {contractResearchPanel}
      {!position && (
        <GutterBox sx={{ mt: 2 }}>
          <Alert severity="warning">No position selected..</Alert>
        </GutterBox>
      )}
    </ResponsiveContainer>
  );
};
