import { FunctionComponent, useEffect } from 'react';

import { Box, Button, ButtonGroup, Card, Grid, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux-hooks';
import { setOrderAction, setOptionRight } from '../../../../store/order-option/reducer';

interface Props {
  reloadOptionChain: () => void;
}

const OrderTypePanel: FunctionComponent<Props> = ({ reloadOptionChain }) => {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const { orderAction, optionRight } = orderOptionState;

  const dispatch = useAppDispatch();

  useEffect(() => {
    reloadOptionChain();
  }, [orderAction, optionRight]);

  return (
    <Card sx={{ my: 2, p: 1 }}>
      <Box>
        <Typography variant="h6" sx={{ ml: 1, mb: 2 }} component="div">
          Order Type
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button onClick={() => dispatch(setOrderAction('BUY'))} variant={orderAction === 'BUY' ? 'contained' : 'outlined'}>
                Buy
              </Button>
              <Button onClick={() => dispatch(setOrderAction('SELL'))} variant={orderAction === 'SELL' ? 'contained' : 'outlined'}>
                Sell
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonGroup variant="outlined" fullWidth>
              <Button onClick={() => dispatch(setOptionRight('CALL'))} variant={optionRight === 'CALL' ? 'contained' : 'outlined'}>
                Call
              </Button>
              <Button onClick={() => dispatch(setOptionRight('PUT'))} variant={optionRight === 'PUT' ? 'contained' : 'outlined'}>
                Put
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default OrderTypePanel;
